import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
// import '@angular/compiler';

import {AppModule} from './app/app.module';
import {environment} from '@environments/environment';
import {ConfigService} from '@app/core/services/config.service';

if (environment.production) {
  enableProdMode();
}
ConfigService.loadConfig().then(() => {
  platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.error(err));
});

