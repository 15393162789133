import {SelectionModel} from '@angular/cdk/collections';
import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {ActivatedRoute, Router} from '@angular/router';
import {CommonDatasource} from '@app/core/datasources';
import {SubheaderService} from '@app/core/services';
import {PerfectScrollbarComponent} from 'ngx-perfect-scrollbar';

@Component({
  selector: 'app-base-table',
  templateUrl: './base-table.component.html',
  styleUrls: ['./base-table.component.scss'],
})
export class BaseTableComponent implements OnInit {
  @Input() selection: SelectionModel<any>;
  @Input() showFilters = false;
  @Input() dataSource: CommonDatasource<any>;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(PerfectScrollbarComponent, {static: true}) scroll: PerfectScrollbarComponent;

  constructor(
    private subheaderService: SubheaderService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.subheaderService.onButtonClick('filters').pipe().subscribe(() => {
      this.showFilters = !this.showFilters;
    });
  }

  handlePageEvent(): void {
    if (this.scroll) {
      this.scroll.directiveRef.scrollTo(0, 0);
    }
  }

  closeView(): void {
    this.router.navigate([{outlets: {view_item: null}}], {
      relativeTo: this.activatedRoute
    });
  }
}
