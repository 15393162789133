import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SETTINGS_GET_URL} from '@app/config/api-routes.config';
import {Configuration} from '@app/core/models';
import {DefaultDataService, HttpUrlGenerator} from '@ngrx/data';
import {ConfigService} from '@app/core/services/config.service';
import {Update} from '@ngrx/entity';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class SettingsDataService extends DefaultDataService<Configuration> {
  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    private config: ConfigService
  ) {
    super('Configuration', http, httpUrlGenerator);

    httpUrlGenerator.registerHttpResourceUrls({
      Configuration: {
        entityResourceUrl: `${this.config.get('api.baseUrl')}${SETTINGS_GET_URL}`,
        collectionResourceUrl: `${this.config.get('api.baseUrl')}${SETTINGS_GET_URL}`,
      }
    });
  }

  private static mapResource(configuration: Configuration): Configuration {
    return {
      ...configuration,
    };
  }

  update(update: Update<Configuration>): Observable<Configuration> {
    return this.execute('PUT', `${this.config.get('api.baseUrl')}${SETTINGS_GET_URL}/${update.id}`, update.changes).pipe(
      map((response: any) => response.data),
      map(configuration => SettingsDataService.mapResource(configuration))
    );
  }
}
