import {Injectable} from '@angular/core';
import {UserDataService} from '@app/core/store/dataservices';
import {UserService} from '@app/core/store/services';
import {EntityAction, EntityOp} from '@ngrx/data';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, exhaustMap, map} from 'rxjs/operators';

@Injectable()
export class UsersEffects {
  @Effect({dispatch: true})
  userChangePassword$ = this.actions$.pipe(
    ofType(`[User] @app/user/change-password`),
    exhaustMap((action: EntityAction) => {
      return this.userDataService.changePassword(action.payload.data).pipe(
        map(() => {
          return this.userService.createEntityAction(EntityOp.SAVE_UPDATE_ONE_SUCCESS, {
            ...{...action.payload.data},
            changed: true
          }, {correlationId: action.payload.correlationId});
        }),
        catchError(error => of(this.userService.createEntityAction(EntityOp.SAVE_UPDATE_ONE_ERROR, {
          error: error,
          originalAction: action
        }, {correlationId: action.payload.correlationId})))
      );
    }),
  );

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private userDataService: UserDataService
  ) {
  }
}
