import { Component } from '@angular/core';

/**
 * Sample context menu dropdown
 */
@Component({
    selector: 'kt-context-menu',
    templateUrl: './context-menu.component.html',
    styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent {
}
