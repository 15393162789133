import {Injectable} from '@angular/core';
import {BATCH_POST_URL} from '@app/config/api-routes.config';
import {DeviceType} from '@app/core/models';
import {
  ChangeSet,
  ChangeSetItem,
  changeSetItemFactory as cif,
  CorrelationIdGenerator,
  EntityActionOptions,
  EntityCacheDispatcher,
  EntityCollectionServiceBase,
  EntityCollectionServiceElementsFactory,
  QueryParams
} from '@ngrx/data';
import {Actions} from '@ngrx/effects';
import {Observable, of} from 'rxjs';
import {distinctUntilChanged, switchMap} from 'rxjs/operators';
import {ConfigService} from '@app/core/services/config.service';

@Injectable({providedIn: 'root'})
export class DeviceTypeService extends EntityCollectionServiceBase<DeviceType> {
  public lastQuery: any;
  protected defaultQueryParams: QueryParams = {
    page: '1',
    limit: '20',
    include: '',
    sort: 'title',
    order: 'asc',
  };

  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private entityCacheDispatcher: EntityCacheDispatcher,
    private actions$: Actions,
    private correlationIdGenerator: CorrelationIdGenerator,
    private config: ConfigService
  ) {
    super('DeviceType', serviceElementsFactory);

    this.actions$ = actions$;
    this.correlationIdGenerator = correlationIdGenerator;

    (this.selectors$ as any).lastQuery$.subscribe((params) => this.lastQuery = params);
  }

  getWithQuery(queryParams?: QueryParams | any, options?: EntityActionOptions): Observable<DeviceType[]> {
    queryParams = {...this.defaultQueryParams, ...this.lastQuery, ...(queryParams ? queryParams : {})};

    return super.getWithQuery(queryParams, options);
  }

  delete(entity: DeviceType | any, options?: EntityActionOptions): Observable<number | string> {
    return super.delete(entity, {...options, isOptimistic: false});
  }

  batchDelete(ids: any[]): Observable<ChangeSet> {
    const changes: ChangeSetItem[] = [
      cif.delete('DeviceType', ids)
    ];

    return this.entityCacheDispatcher.saveEntities(changes, `${this.config.get('api.baseUrl')}${BATCH_POST_URL}`);
  }

  selectEntity(key: any): Observable<DeviceType> {
    return this.entityMap$.pipe(
      switchMap(entities => entities.hasOwnProperty(key) ? of(entities[key]) : this.getByKey(key)),
      distinctUntilChanged(),
    );
  }
}
