import {Injectable} from '@angular/core';
import {EntityAction, EntityOp} from '@ngrx/data';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, exhaustMap, map} from 'rxjs/operators';
import {UpgradeTaskDataService} from '@app/core/store/dataservices/upgrade-task-data.service';
import {UpgradeTaskService} from '@app/core/store/services/upgrade-task.service';

@Injectable()
export class UpgradeTasksEffects {
  upgradeTaskCancel$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(`[UpgradeTask] @app/upgrade-task/cancel`),
      exhaustMap((action: EntityAction) => {
        return this.upgradeTaskDataService.cancel(action.payload.data).pipe(
          map((task) => {
            const changes = {
              id: task.id,
              status: task.status
            };

            return this.upgradeTaskService.createEntityAction(EntityOp.SAVE_UPDATE_ONE_SUCCESS, {
              ...{id: task.id, changes},
              changed: true
            }, {correlationId: action.payload.correlationId});
          }),
          catchError(error => of(this.upgradeTaskService.createEntityAction(EntityOp.SAVE_UPDATE_ONE_ERROR, {
            error: error,
            originalAction: action
          }, {correlationId: action.payload.correlationId})))
        );
      }),
    );
  }, {dispatch: true});

  upgradeTaskStart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(`[UpgradeTask] @app/upgrade-task/start`),
      exhaustMap((action: EntityAction) => {
        return this.upgradeTaskDataService.start(action.payload.data).pipe(
          map((task) => {
            const changes = {
              id: task.id,
              readyBlocks: task.readyBlocks,
              totalBlocks: task.totalBlocks,
              status: task.status
            };

            return this.upgradeTaskService.createEntityAction(EntityOp.SAVE_UPDATE_ONE_SUCCESS, {
              ...{id: task.id, changes},
              changed: true
            }, {correlationId: action.payload.correlationId});
          }),
          catchError(error => of(this.upgradeTaskService.createEntityAction(EntityOp.SAVE_UPDATE_ONE_ERROR, {
            error: error,
            originalAction: action
          }, {correlationId: action.payload.correlationId})))
        );
      }),
    );
  }, {dispatch: true});

  constructor(
    private actions$: Actions,
    private upgradeTaskService: UpgradeTaskService,
    private upgradeTaskDataService: UpgradeTaskDataService
  ) {
  }
}
