import {Type} from '@angular/core';
import {AuthEffects} from '@app/core/store/effects/auth.effects';
import {BrokersEffects} from '@app/core/store/effects/brokers.effects';
import {GatewaysEffects} from '@app/core/store/effects/gateways.effects';
import {LocksEffects} from '@app/core/store/effects/locks.effects';
import {UsersEffects} from '@app/core/store/effects/users.effects';
import {UpgradeTasksEffects} from '@app/core/store/effects/upgrade-tasks.effects';

export * from './auth.effects';
export * from './brokers.effects';
export * from './gateways.effects';
export * from './layout.effects';
export * from './locks.effects';
export * from './users.effects';
export * from './upgrade-tasks.effects';


export const appEffects: Type<any>[] = [
  AuthEffects, BrokersEffects, GatewaysEffects, LocksEffects, UsersEffects, UpgradeTasksEffects
];
