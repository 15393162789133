import {Injectable} from '@angular/core';
import {AppState} from '@app/core/store/reducers';
import {Actions} from '@ngrx/effects';
import {Store} from '@ngrx/store';

@Injectable()
export class LayoutEffects {
  constructor(private actions$: Actions, private store: Store<AppState>) {
    /*this.router.events
        .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
        .subscribe((events: RoutesRecognized[]) => {
            console.log('previous url', events[0].urlAfterRedirects);
            console.log('current url', events[1].urlAfterRedirects);
        });*/
  }
}
