import {Injectable} from '@angular/core';
import {StorageService} from '@app/core/services/storage.service';
import {TranslateService} from '@ngx-translate/core';

export interface Locale {
  lang: string;
  // tslint:disable-next-line:ban-types
  data: Object;
}

@Injectable({providedIn: 'root'})
export class TranslationService {
  // Private properties
  private langIds: any = [];

  /**
   * Service Constructor
   */
  constructor(private translate: TranslateService, private storage: StorageService) {
    // add new langIds to the list
    this.translate.addLangs(['en']);

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en');
  }

  /**
   * Load Translation
   */
  loadTranslations(...args: Locale[]): void {
    const locales = [...args];

    locales.forEach(locale => {
      // use setTranslation() with the third argument set to true
      // to append translations instead of replacing them
      this.translate.setTranslation(locale.lang, locale.data, true);

      this.langIds.push(locale.lang);
    });

    // add new languages to the list
    this.translate.addLangs(this.langIds);
  }

  /**
   * Setup language
   */
  setLanguage(lang): void {
    if (lang) {
      this.translate.use(this.translate.getDefaultLang());
      this.translate.use(lang);
      this.storage.setItem('language', lang);
    }
  }

  /**
   * Returns selected language
   */
  getSelectedLanguage(): any {
    return this.storage.getItem('language') || this.translate.getDefaultLang();
  }
}
