import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {NgbPopoverConfig} from "@ng-bootstrap/ng-bootstrap";
import {Subscription} from 'rxjs';
import {LayoutConfigService, SplashScreenService, TranslationService} from './core/services';
import {locale as enLang} from './translations/en';

@Component({
  selector: 'body[kt-root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
  title = '4Suites';
  loader: boolean;
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  constructor(private translationService: TranslationService,
              private router: Router,
              private popoverConfig: NgbPopoverConfig,
              private layoutConfigService: LayoutConfigService,
              private splashScreenService: SplashScreenService) {

    // register translations
    this.translationService.loadTranslations(enLang);

    popoverConfig.triggers = 'mouseenter:mouseleave';
    popoverConfig.placement = 'bottom';
  }

  ngOnInit(): void {
    // enable/disable loader
    this.loader = this.layoutConfigService.getConfig('loader.enabled');

    const routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // hide splash screen
        this.splashScreenService.hide();

        // scroll to top on every route change
        window.scrollTo(0, 0);

        // to display back the body content
        setTimeout(() => {
          document.body.classList.add('kt-page--loaded');
        }, 500);
      }
    });
    this.unsubscribe.push(routerSubscription);
  }

  ngOnDestroy() {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }
}
