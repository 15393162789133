import {OverlayModule} from '@angular/cdk/overlay';
import {registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import localeCa from '@angular/common/locales/en-NL';
import localeCaExtra from '@angular/common/locales/extra/en-NL';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LayoutConfig} from '@app/config/layout.config';
import {CoreModule} from '@app/core/core.module';
import {RefreshTokenInterceptor} from '@app/core/http-interceptors';
import {LayoutConfigService, StorageService} from '@app/core/services';
import {AdditionalPropertyPersistenceResultHandler} from '@app/core/store/dataservices/additional-property-persistence-result-handler';
import {appEffects} from '@app/core/store/effects';
import {initializeEntityMetadata} from '@app/core/store/entity-metadata';
import {APP_REDUCERS_TOKEN, getAppReducers, metaReducers} from '@app/core/store/reducers';
import {AdditionalEntityCollectionReducerMethodsFactory} from '@app/core/store/reducers/additional-entity-collection-reducer-methods-factory';
import {PartialsModule} from '@app/views/partials/partials.module';
import {ThemeModule} from '@app/views/theme/theme.module';
import {JWT_OPTIONS, JwtConfig, JwtModule} from '@auth0/angular-jwt';
import {ENTITY_METADATA_TOKEN, EntityCollectionReducerMethodsFactory, EntityDataModule, PersistenceResultHandler} from '@ngrx/data';
import {EffectsModule} from '@ngrx/effects';
import {StoreRouterConnectingModule} from '@ngrx/router-store';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {TranslateModule} from '@ngx-translate/core';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {NgxPermissionsModule} from 'ngx-permissions';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ConfigService} from '@app/core/services/config.service';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelSpeed: 0.5,
  swipeEasing: true,
  minScrollbarLength: 40,
  maxScrollbarLength: 300,
};

/*const defaultDataServiceConfig: DefaultDataServiceConfig = {
  root: environment.apiBaseUrl,
  timeout: 3000,
};*/

export const initializeLayoutConfig = (appConfig: LayoutConfigService) => () =>  {
  return new Promise((resolve) => {
    if (appConfig.getConfig() === null) {
      appConfig.loadConfigs(new LayoutConfig().configs);
    }

    resolve(true);
  });
};

function jwtOptionsFactory(storage: StorageService, config: ConfigService): JwtConfig {
  return {
    tokenGetter: () => storage.getItem('accessToken'),
    allowedDomains: [new RegExp('^([a-z]+\.)+4suites\.[a-z]+$'), new RegExp('^localhost:\\d{1,4}$'), new RegExp('^0\.0\.0\.0:\\d{1,4}$')],
    disallowedRoutes: [`${config.get('api.baseUrl')}/auth/login_check`]
  };
}

registerLocaleData(localeCa, localeCaExtra);

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    CoreModule,

    StoreModule.forRoot(APP_REDUCERS_TOKEN, {
      metaReducers,
      runtimeChecks: {
        // strictStateImmutability: true,
        // strictActionImmutability: true,
      }
    }),
    EffectsModule.forRoot(appEffects),
    EntityDataModule,
    StoreRouterConnectingModule.forRoot({stateKey: 'router'}),
    StoreDevtoolsModule.instrument(),

    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    NgxPermissionsModule.forRoot(),
    OverlayModule,
    TranslateModule.forRoot(),

    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [StorageService, ConfigService]
      }
    }),

    ThemeModule,
    PartialsModule
  ],
  providers: [
    {provide: APP_REDUCERS_TOKEN, useFactory: getAppReducers, deps: [StorageService]},
    {provide: ENTITY_METADATA_TOKEN, multi: true, useFactory: initializeEntityMetadata, deps: [StorageService]},
    {provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true},
    {provide: PersistenceResultHandler, useClass: AdditionalPropertyPersistenceResultHandler},
    {provide: EntityCollectionReducerMethodsFactory, useClass: AdditionalEntityCollectionReducerMethodsFactory},
    // {provide: DefaultDataServiceConfig, useValue: defaultDataServiceConfig},
    {provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG},
    /*{provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig},*/
    {provide: APP_INITIALIZER, useFactory: initializeLayoutConfig, deps: [LayoutConfigService], multi: true},
    {provide: LOCALE_ID, useValue: 'en-NL'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
