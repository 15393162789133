import {LayoutActions} from '@app/core/store/actions/layout.actions';

export interface LayoutState {
  quick_panel: {};
}

export const initialSubheaderState: LayoutState = {
  quick_panel: {}
};

export function layoutReducer(state = initialSubheaderState, action: LayoutActions): LayoutState {
  switch (action.type) {
    default:
      return state;
  }
}
