export const frequencies: readonly { id: number, description: string }[] = [
  {
    id: 1,
    description: '915MHz US Frequency band operating mode #1'
  },
  {
    id: 3,
    description: '863MHz ETSI Frequency band operating mode #3'
  }
];
