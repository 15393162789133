import {Component, Input} from '@angular/core';

@Component({
  selector: 'dot-badge',
  template: `
      <span class="kt-badge kt-badge--{{ color }} kt-badge--dot"></span>&nbsp;
      <span class="kt--font-bold kt-font-{{ color }}">
        <ng-content></ng-content>
      </span>
  `
})
export class DotBadgeComponent {
  @Input() color = '';
}
