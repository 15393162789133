import {Injectable} from '@angular/core';
import {GatewayDataService} from '@app/core/store/dataservices';
import {GatewayService} from '@app/core/store/services';
import {EntityAction, EntityOp} from '@ngrx/data';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, exhaustMap, map} from 'rxjs/operators';

@Injectable()
export class GatewaysEffects {
  @Effect({dispatch: true})
  gatewayAttachToOwner$ = this.actions$.pipe(
    ofType(`[Gateway] @app/gateway/add-to-owner`),
    exhaustMap((action: EntityAction) => {
      return this.gatewayDataService.attachToOwner(action.payload.data).pipe(
        map((gateway) => {
          const changes = {...action.payload.data.changes, user: gateway?.user};

          return this.gatewayService.createEntityAction(EntityOp.SAVE_UPDATE_ONE_SUCCESS, {
            ...{...action.payload.data, changes},
            changed: true
          }, {correlationId: action.payload.correlationId});
        }),
        catchError(error => of(this.gatewayService.createEntityAction(EntityOp.SAVE_UPDATE_ONE_ERROR, {
          error: error,
          originalAction: action
        }, {correlationId: action.payload.correlationId})))
      );
    }),
  );

  @Effect({dispatch: true})
  gatewayClear$ = this.actions$.pipe(
    ofType(`[Gateway] @app/gateway/clear`),
    exhaustMap((action: EntityAction) => {
      return this.gatewayDataService.clear(action.payload.data).pipe(
        map(() => this.gatewayService.createEntityAction(EntityOp.SAVE_UPDATE_ONE_SUCCESS, {
          ...action.payload.data,
          changed: true
        }, {correlationId: action.payload.correlationId})),
        catchError(error => of(this.gatewayService.createEntityAction(EntityOp.SAVE_UPDATE_ONE_ERROR, {
          error: error,
          originalAction: action
        }, {correlationId: action.payload.correlationId})))
      );
    }),
  );

  @Effect({dispatch: true})
  gatewayClaim$ = this.actions$.pipe(
    ofType(`[Gateway] @app/gateway/claim`),
    exhaustMap((action: EntityAction) => {
      return this.gatewayDataService.claim(action.payload.data).pipe(
        map((gateway) => {
          const changes = {
            ...action.payload.data.changes,
            metadata: gateway.metadata,
            claimed: gateway.claimed,
            user: gateway?.user,
            broker: gateway?.broker,
          };

          return this.gatewayService.createEntityAction(EntityOp.SAVE_UPDATE_ONE_SUCCESS, {
            ...{...action.payload.data, changes},
            changed: true
          }, {correlationId: action.payload.correlationId});
        }),
        catchError(error => of(this.gatewayService.createEntityAction(EntityOp.SAVE_UPDATE_ONE_ERROR, {
          error: error,
          originalAction: action
        }, {correlationId: action.payload.correlationId})))
      );
    }),
  );

  constructor(
    private actions$: Actions,
    private gatewayService: GatewayService,
    private gatewayDataService: GatewayDataService
  ) {
  }
}
