import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LOCKS_GET_URL} from '@app/config/api-routes.config';
import {Lock} from '@app/core/models';
import {ID} from '@app/core/types';
import {TypesUtilsService} from '@app/core/utils/types-utils.service';
import {DefaultDataService, HttpUrlGenerator} from '@ngrx/data';
import {Update} from '@ngrx/entity';
import * as moment from 'moment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ConfigService} from '@app/core/services/config.service';

@Injectable()
export class LockDataService extends DefaultDataService<Lock> {
  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    private typesUtilsService: TypesUtilsService,
    private config: ConfigService
  ) {
    super('Lock', http, httpUrlGenerator);

    httpUrlGenerator.registerHttpResourceUrls({
      Lock: {
        entityResourceUrl: `${this.config.get('api.baseUrl')}${LOCKS_GET_URL}`,
        collectionResourceUrl: `${this.config.get('api.baseUrl')}${LOCKS_GET_URL}`,
      }
    });
  }

  private static mapResource(lock: Lock): Lock {
    return {
      ...lock,
      createdAt: moment(lock.createdAt),
      updatedAt: moment(lock.updatedAt),
    };
  }

  delete(key: number | string): Observable<number | string> {
    return this.execute('DELETE', `${this.config.get('api.baseUrl')}${LOCKS_GET_URL}/${key}`).pipe(
      map(() => key)
    );
  }

  update(update: Update<Lock>): Observable<Lock> {
    return this.execute('PUT', `${this.config.get('api.baseUrl')}${LOCKS_GET_URL}/${update.id}?include=type,firmware`, update.changes).pipe(
      map((response: any) => response.data),
      map(lock => LockDataService.mapResource(lock))
    );
  }

  claim(update: Update<Lock>): Observable<Lock> {
    return this.execute('POST', `${this.config.get('api.baseUrl')}${LOCKS_GET_URL}/claim`, update.changes, {params: {include: 'gateway,type,firmware'}}).pipe(
      map((response: any) => response.data),
      map(lock => LockDataService.mapResource(lock))
    );
  }

  upgrade(update: Update<Lock>): Observable<Lock> {
    return this.execute('POST', `${this.config.get('api.baseUrl')}${LOCKS_GET_URL}/${update.id}/upgrade-firmware`, update.changes);
  }

  refreshFirmware(id: ID): Observable<Lock> {
    return this.execute(
      'POST',
      `${this.config.get('api.baseUrl')}${LOCKS_GET_URL}/${id}/update-firmware-version-info`,
      null,
      {params: {include: 'firmware'}}
    ).pipe(
      map((response: any) => response.data),
      map(lock => LockDataService.mapResource(lock))
    );
  }

  clear(update: Update<Lock>): Observable<Lock> {
    return this.execute('PUT', `${this.config.get('api.baseUrl')}${LOCKS_GET_URL}/${update.id}/clear`, update.changes, {params: {include: 'gateway,type,firmware'}}).pipe(
      map((response: any) => response.data),
      map(lock => LockDataService.mapResource(lock))
    );
  }

  getById(data: any): Observable<Lock> {
    if (typeof (data) === 'object') {
      return this.execute('GET', `${this.config.get('api.baseUrl')}${LOCKS_GET_URL}/${data.key}`, {}, {params: data.query}).pipe(
        map((response: any) => response.data),
        map(lock => LockDataService.mapResource(lock))
      );
    } else {
      return this.execute('GET', `${this.config.get('api.baseUrl')}${LOCKS_GET_URL}/${data}`).pipe(
        map((response: any) => response.data),
        map(lock => LockDataService.mapResource(lock))
      );
    }
  }

  getByIdWithQuery(id: ID, params: string | any): Observable<Lock> {
    return this.execute('GET', `${this.config.get('api.baseUrl')}${LOCKS_GET_URL}/${id}`, {}, {params: params}).pipe(
      map((response: any) => response.data),
      map(lock => LockDataService.mapResource(lock))
    );
  }

  // ToDo replace any with a proper object type
  getWithQuery(params: string | any): Observable<any> {
    const filters = {...params?.filters};

    params = (params instanceof Object) ? {...this.typesUtilsService.objectWithoutProp(params, 'filters'), ...filters} : params;

    return this.execute('GET', `${this.config.get('api.baseUrl')}${LOCKS_GET_URL}`, {}, {params: params}).pipe(
      map(response => {
        const data = (response as any).data.map(lock => LockDataService.mapResource(lock));

        return {...response, data};
      })
    );
  }
}
