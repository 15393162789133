import {Injectable} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {AppState} from '@app/core/store/reducers';
import {Store} from '@ngrx/store';
import {merge, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AbstractForm} from '@gatman/ngx-forms';

@Injectable()
export class EntityFilterService {
  constructor(protected store: Store<AppState>) {
  }

  changes(
    paginator?: MatPaginator,
    sort?: MatSort,
    filterForm?: FormGroup,
    ...additionalObservables: Observable<string>[]
  ): Observable<any> {
    const dataChanges: Observable<any>[] = [
      // this.store.select(quickPanelCheckedCompaniesState)
    ];

    additionalObservables.forEach(value => dataChanges.push(value));

    if (paginator) {
      dataChanges.push(paginator.page.asObservable());
    }

    if (sort) {
      dataChanges.push(sort.sortChange.asObservable());
    }

    if (filterForm) {
      dataChanges.push(filterForm.valueChanges);
    }

    return merge(...dataChanges).pipe(
      map((filters) => {
        let params: any = {
          filters: {}
        };
        // params = {...params, filters: {}};

        if (filterForm) {
          const filterObject = (filterForm instanceof AbstractForm)
            ? {...filterForm.toSimpleObject()}
            : filterForm.value;
          Object.keys(filterObject).forEach(
            (key) => (filterObject[key] == null || filterObject[key] === 'null') && delete filterObject[key]
          );
          params = {...params, filters: {...filterObject}};
        }
        if (paginator) {
          params = {...params, page: paginator.pageIndex + 1, limit: paginator.pageSize};
        }
        if (sort) {
          params = {...params, sort: sort.active, order: sort.direction};
        }
        if (
          !filters.hasOwnProperty('active') &&
          !filters.hasOwnProperty('direction') &&
          !filters.hasOwnProperty('pageIndex')
        ) {
          params = {...params, page: 1};
        }

        if (typeof filters === 'string') {
          params = {...params, filters: {...params.filters, q: filters}};
        }

        return params;
      })
    );
  }
}
