import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {TranslationService} from '@app/core/services';

interface LanguageFlag {
    lang: string;
    name: string;
    flag: string;
    active?: boolean;
}

@Component({
    selector: 'kt-language-selector',
    templateUrl: './language-selector.component.html',
})
export class LanguageSelectorComponent implements OnInit {
    // Public properties
    @HostBinding('class') classes = '';
    @Input() iconType: '' | 'brand';

    language: LanguageFlag;
    languages: LanguageFlag[] = [
        {
            lang: 'en',
            name: 'English',
            flag: './assets/media/flags/012-uk.svg'
        },
    ];

    constructor(private translationService: TranslationService, private router: Router) {
    }

    ngOnInit() {
        this.setSelectedLanguage();
        this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe(() => {
                this.setSelectedLanguage();
            });
    }

    /**
     * Set language
     *
     * @param lang: any
     */
    setLanguage(lang) {
        this.languages.forEach((language: LanguageFlag) => {
            if (language.lang === lang) {
                language.active = true;
                this.language = language;
            } else {
                language.active = false;
            }
        });
        this.translationService.setLanguage(lang);
    }

    /**
     * Set selected language
     */
    setSelectedLanguage(): any {
        this.setLanguage(this.translationService.getSelectedLanguage());
    }
}
