import {Inject, Injectable} from '@angular/core';
import {LOCAL_STORAGE} from '@ng-web-apis/common';

@Injectable({providedIn: 'root'})
export class StorageService {
  private static PREFIX = 'registry_';

  storage: Storage;

  constructor(@Inject(LOCAL_STORAGE) storage: Storage) {
    this.storage = storage;
  }

  setItem(key: string, value: string): void {
    this.storage.setItem(`${StorageService.PREFIX}${key}`, value);
  }

  getItem(key: string): string | null {
    return this.storage.getItem(`${StorageService.PREFIX}${key}`);
  }

  removeItem(key: string): void {
    this.storage.removeItem(`${StorageService.PREFIX}${key}`);
  }

  clear(): void {
    this.storage.clear();
  }
}
