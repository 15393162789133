import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {User} from '@app/core/models';
import {currentUser} from '@app/core/store/selectors/auth.selectors';
import * as AuthActions from '@app/core/store/actions/auth.actions';
import {AppState} from '@app/core/store/reducers';

@Component({
  selector: 'kt-user-profile',
  templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit {
  user$: Observable<User>;

  @Input() greeting = true;
  @Input() badge: boolean;
  @Input() icon: boolean;

  constructor(private store: Store<AppState>) {
  }

  ngOnInit(): void {
    this.user$ = this.store.pipe(select(currentUser));
  }

  logout(): void {
    this.store.dispatch(AuthActions.logout());
  }
}
