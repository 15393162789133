import {Injectable} from '@angular/core';
import {Configuration} from '@app/core/models';
import {EntityCollectionServiceBase, EntityCollectionServiceElementsFactory} from '@ngrx/data';
import {Observable, of} from 'rxjs';
import {distinctUntilChanged, switchMap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class SettingsService extends EntityCollectionServiceBase<Configuration> {
  public lastQuery: any;

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('Configuration', serviceElementsFactory);

    (this.selectors$ as any).lastQuery$.subscribe((params) => this.lastQuery = params);
  }

  selectEntity(key: any, query?: any): Observable<Configuration> {
    return this.entityMap$.pipe(
      switchMap(entities => entities.hasOwnProperty(key) ? of(entities[key]) : this.getByKey({key: key, query: query})),
      distinctUntilChanged(),
    );
  }
}
