import {CommonModule} from '@angular/common';
import {Injector, NgModule} from '@angular/core';
import {
  ContentAnimateDirective,
  HeaderDirective,
  MenuDirective,
  OffcanvasDirective,
  ScrollTopDirective,
  SparklineChartDirective,
  StickyDirective,
  TabClickEventDirective,
  ToggleDirective
} from '@app/core/directives';
import {FirstLetterPipe, GetObjectPipe, JoinPipe, Nl2brPipe, SafePipe, TimeElapsedPipe} from '@app/core/pipes';
import {EntityFilterService} from '@app/core/services';
import {
  ApiKeyDataService,
  BrokerDataService,
  DeviceTypeDataService,
  FirmwareDataService,
  GatewayDataService,
  LockDataService,
  SettingsDataService,
  UserDataService
} from '@app/core/store/dataservices';
import {EntityDataService} from '@ngrx/data';
import {UpgradeTaskDataService} from '@app/core/store/dataservices/upgrade-task-data.service';
import {FrequencyDescription} from '@app/core/pipes/frequencyDescription.pipe';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    // directives
    ScrollTopDirective,
    HeaderDirective,
    OffcanvasDirective,
    ToggleDirective,
    MenuDirective,
    TabClickEventDirective,
    SparklineChartDirective,
    ContentAnimateDirective,
    StickyDirective,
    // pipes
    Nl2brPipe,
    TimeElapsedPipe,
    JoinPipe,
    GetObjectPipe,
    SafePipe,
    FirstLetterPipe,
    FrequencyDescription,
  ],
  exports: [
    // directives
    ScrollTopDirective,
    HeaderDirective,
    OffcanvasDirective,
    ToggleDirective,
    MenuDirective,
    TabClickEventDirective,
    SparklineChartDirective,
    ContentAnimateDirective,
    StickyDirective,
    // pipes
    TimeElapsedPipe,
    JoinPipe,
    GetObjectPipe,
    SafePipe,
    FirstLetterPipe,
    FrequencyDescription,
    Nl2brPipe,
  ],
  providers: [
    EntityFilterService,

    GatewayDataService,
    BrokerDataService,
    LockDataService,
    ApiKeyDataService,
    UserDataService,
    DeviceTypeDataService,
    FirmwareDataService,
    UpgradeTaskDataService,
    SettingsDataService,
  ]
})
export class CoreModule {
  constructor(entityDataService: EntityDataService, injector: Injector) {
    entityDataService.registerServices({
      Broker: injector.get(BrokerDataService),
      Gateway: injector.get(GatewayDataService),
      Lock: injector.get(LockDataService),
      User: injector.get(UserDataService),
      ApiKey: injector.get(ApiKeyDataService),
      DeviceType: injector.get(DeviceTypeDataService),
      Firmware: injector.get(FirmwareDataService),
      UpgradeTask: injector.get(UpgradeTaskDataService),
      Configuration: injector.get(SettingsDataService),
    });
  }
}
