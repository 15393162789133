import {FocusMonitor} from '@angular/cdk/a11y';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {Component, ElementRef, Input, OnDestroy, Optional, Self, ViewChild} from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormControl, NgControl} from '@angular/forms';
import {MatFormFieldControl} from '@angular/material/form-field';
import {EntityFilterService} from '@app/core/services';
import {NgSelectComponent} from '@ng-select/ng-select';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {tap} from 'rxjs/operators';

@Component({
  selector: 'app-roles-select',
  templateUrl: 'roles-select.component.html',
  providers: [{provide: MatFormFieldControl, useExisting: RolesSelectComponent}],
  styleUrls: ['roles-select.component.scss']
})
export class RolesSelectComponent implements ControlValueAccessor, MatFormFieldControl<string[] | string>, OnDestroy {
  static nextId = 0;
  formControl: FormControl;
  stateChanges = new Subject<void>();
  focused = false;
  errorState = false;
  controlType = 'app-roles-select';
  id = `app-roles-select-${RolesSelectComponent.nextId++}`;
  describedBy = '';
  roles$: Observable<{ label: string, group: string }[]>;
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() multiple: boolean;
  @Input() allowNew: boolean;
  @ViewChild(NgSelectComponent, {static: true}) ngSelectComponent: NgSelectComponent;
  protected roles = [
    {label: 'ROLE_ADMIN', group: 'GENERIC'},
    {label: 'ROLE_USER', group: 'GENERIC'},
    {label: 'ROLE_MANAGER', group: 'GENERIC'},

    {label: 'ROLE_ADMIN_MANAGER', group: 'ADMIN MANAGEMENT'},
    {label: 'ROLE_ADMIN_VIEW', group: 'ADMIN MANAGEMENT'},
    {label: 'ROLE_ADMIN_EDIT', group: 'ADMIN MANAGEMENT'},
    {label: 'ROLE_ADMIN_DELETE', group: 'ADMIN MANAGEMENT'},

    {label: 'ROLE_USER_MANAGER', group: 'USER MANAGEMENT'},
    {label: 'ROLE_USER_VIEW', group: 'USER MANAGEMENT'},
    {label: 'ROLE_USER_EDIT', group: 'USER MANAGEMENT'},
    {label: 'ROLE_USER_DELETE', group: 'USER MANAGEMENT'},

    {label: 'ROLE_BROKER_MANAGER', group: 'BROKER MANAGEMENT'},
    {label: 'ROLE_BROKER_VIEW', group: 'BROKER MANAGEMENT'},
    {label: 'ROLE_BROKER_EDIT', group: 'BROKER MANAGEMENT'},
    {label: 'ROLE_BROKER_DELETE', group: 'BROKER MANAGEMENT'},

    {label: 'ROLE_FIRMWARE_MANAGER', group: 'FIRMWARE MANAGEMENT'},
    {label: 'ROLE_FIRMWARE_VIEW', group: 'FIRMWARE MANAGEMENT'},
    {label: 'ROLE_FIRMWARE_EDIT', group: 'FIRMWARE MANAGEMENT'},
    {label: 'ROLE_FIRMWARE_DELETE', group: 'FIRMWARE MANAGEMENT'},

    {label: 'ROLE_GATEWAY_MANAGER', group: 'GATEWAY MANAGEMENT'},
    {label: 'ROLE_GATEWAY_VIEW', group: 'GATEWAY MANAGEMENT'},
    {label: 'ROLE_GATEWAY_EDIT', group: 'GATEWAY MANAGEMENT'},
    {label: 'ROLE_GATEWAY_DELETE', group: 'GATEWAY MANAGEMENT'},

    {label: 'ROLE_LOCK_MANAGER', group: 'LOCK MANAGEMENT'},
    {label: 'ROLE_LOCK_VIEW', group: 'LOCK MANAGEMENT'},
    {label: 'ROLE_LOCK_EDIT', group: 'LOCK MANAGEMENT'},
    {label: 'ROLE_LOCK_DELETE', group: 'LOCK MANAGEMENT'},

    {label: 'ROLE_DEVICE_TYPE_MANAGER', group: 'DEVICE TYPE MANAGEMENT'},
    {label: 'ROLE_DEVICE_TYPE_VIEW', group: 'DEVICE TYPE MANAGEMENT'},
    {label: 'ROLE_DEVICE_TYPE_EDIT', group: 'DEVICE TYPE MANAGEMENT'},
    {label: 'ROLE_DEVICE_TYPE_DELETE', group: 'DEVICE TYPE MANAGEMENT'},
  ];

  constructor(
    private formBuilder: FormBuilder,
    private filterService: EntityFilterService,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl) {

    this.formControl = formBuilder.control(null);

    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();

      if (this.focused && !this.roles$) {
        this.loadOptions();
      }
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  get empty(): boolean {
    return !this.formControl.value || this.formControl.value.length === 0;
  }

  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  private _placeholder = 'Select roles...';

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  private _required = false;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private _disabled = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.formControl.disable() : this.formControl.enable();
    this.stateChanges.next();
  }

  @Input()
  get value(): string[] | string {
    return this.formControl.value;
  }

  set value(value: string[] | string | null) {
    this.formControl.setValue(value);

    this.stateChanges.next();
  }

  onChange = (_: any) => {
  }

  onTouched = () => {
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this.isLoading$.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent): void {
  }

  writeValue(value: string[] | string | null): void {
    if (value) {
      this.value = value instanceof Array ? value : [value];
    } else {
      this.value = null;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  emitChangeEvent(): void {
    this.onChange(this.formControl.value);
    this.stateChanges.next();
  }

  private loadOptions(): void {
    this.roles$ = of(this.roles).pipe(
      tap(() => this.isLoading$.next(true)),
      tap(() => {
        this.isLoading$.next(false);
        this.ngSelectComponent.open();
      }),
    );
  }
}
