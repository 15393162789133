import {Component, Input, OnInit} from '@angular/core';
import {AbstractForm} from '@app/core/forms/abstract.form';

@Component({
  selector: 'app-validation-rules',
  templateUrl: 'validation-rules.component.html'
})
export class ValidationRulesComponent implements OnInit {
  @Input() formGroup: AbstractForm;
  @Input() controlName: string;
  @Input() validatorKey: string;

  rules: any[] = [];

  ngOnInit(): void {
    let validators = [];

    if (this.validatorKey && this.formGroup.validators.hasOwnProperty(this.validatorKey)) {
      validators = this.formGroup.validators[this.validatorKey];
    } else if (this.formGroup.validators.hasOwnProperty(this.controlName)) {
      validators = this.formGroup.validators[this.controlName];
    }

    for (const rule of validators) {
      this.rules.push({
        id: rule,
        violated: this.formGroup.get(this.controlName).hasError(rule),
        text: this.formGroup.messages[rule]
      });
    }
  }
}
