import {Injectable} from '@angular/core';
import {BATCH_POST_URL} from '@app/config/api-routes.config';
import {UpgradeTask} from '@app/core/models';
import {
  ChangeSet,
  ChangeSetItem,
  changeSetItemFactory as cif,
  CorrelationIdGenerator,
  EntityAction,
  EntityActionOptions,
  EntityCacheDispatcher,
  EntityCollectionServiceBase,
  EntityCollectionServiceElementsFactory,
  EntityOp,
  QueryParams
} from '@ngrx/data';
import {Actions} from '@ngrx/effects';
import {Observable, of, throwError} from 'rxjs';
import {distinctUntilChanged, filter, switchMap} from 'rxjs/operators';
import {ConfigService} from '@app/core/services/config.service';
import {TypesUtilsService} from '@app/core/utils/types-utils.service';

@Injectable({providedIn: 'root'})
export class UpgradeTaskService extends EntityCollectionServiceBase<UpgradeTask> {
  public lastQuery: any;
  protected defaultQueryParams: QueryParams = {
    page: '1',
    limit: '20',
    include: '',
    sort: 'createdAt',
    order: 'desc',
  };

  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private entityCacheDispatcher: EntityCacheDispatcher,
    private actions$: Actions,
    private correlationIdGenerator: CorrelationIdGenerator,
    private config: ConfigService,
    private typesUtilsService: TypesUtilsService,
  ) {
    super('UpgradeTask', serviceElementsFactory);

    this.actions$ = actions$;
    this.correlationIdGenerator = correlationIdGenerator;

    (this.selectors$ as any).lastQuery$.subscribe((params) => this.lastQuery = params);
  }

  getWithQuery(queryParams?: QueryParams | any, options?: EntityActionOptions): Observable<UpgradeTask[]> {
    queryParams = {
      ...this.defaultQueryParams,
      ...this.typesUtilsService.objectWithoutProp(this.lastQuery, 'filters'),
      filters: (queryParams && queryParams.hasOwnProperty('filters') ? queryParams.filters : this.lastQuery.filters ?? {}),
      ...this.typesUtilsService.objectWithoutProp(queryParams ?? {}, 'filters'),
    };

    return super.getWithQuery(queryParams, options);
  }

  cancel(entity: UpgradeTask): Observable<UpgradeTask> {
    const correlationId = this.correlationIdGenerator.next();

    this.store.dispatch({
      type: '[UpgradeTask] @app/upgrade-task/cancel',
      payload: {
        data: {
          id: entity.id,
          changes: {
            id: entity.id,
          }
        },
        entityName: 'UpgradeTask',
        isOptimistic: false,
        correlationId: correlationId
      }
    });

    return this.actions$.pipe(
      filter((action: EntityAction) => action.payload.correlationId === correlationId),
      switchMap(action => {
        if (action.payload.entityOp === EntityOp.SAVE_UPDATE_ONE_ERROR) {
          return throwError(action.payload.data.error);
        } else {
          return of(action.payload.data);
        }
      })
    );
  }

  start(entity: UpgradeTask, forceStart: boolean = false): Observable<UpgradeTask> {
    const correlationId = this.correlationIdGenerator.next();

    this.store.dispatch({
      type: '[UpgradeTask] @app/upgrade-task/start',
      payload: {
        data: {
          id: entity.id,
          changes: {
            id: entity.id,
            forceStart: forceStart
          }
        },
        entityName: 'UpgradeTask',
        isOptimistic: false,
        correlationId: correlationId
      }
    });

    return this.actions$.pipe(
      filter((action: EntityAction) => action.payload.correlationId === correlationId),
      switchMap(action => {
        if (action.payload.entityOp === EntityOp.SAVE_UPDATE_ONE_ERROR) {
          return throwError(action.payload.data.error);
        } else {
          return of(action.payload.data);
        }
      })
    );
  }

  delete(entity: UpgradeTask | any, options?: EntityActionOptions): Observable<number | string> {
    return super.delete(entity, {...options, isOptimistic: false});
  }

  batchDelete(ids: any[]): Observable<ChangeSet> {
    const changes: ChangeSetItem[] = [
      cif.delete('UpgradeTask', ids)
    ];

    return this.entityCacheDispatcher.saveEntities(changes, `${this.config.get('api.baseUrl')}${BATCH_POST_URL}`);
  }

  selectEntity(key: any, query?: any): Observable<UpgradeTask> {
    return this.entityMap$.pipe(
      switchMap(entities => entities.hasOwnProperty(key) ? of(entities[key]) : this.getByKey({key: key, query: query})),
      distinctUntilChanged(),
    );
  }
}
