export class PageConfig {
  public defaults: any = {
    app_api_keys_list: {
      page: {
        title: 'Api keys',
        desc: 'List of registered api keys',
      },
      subheader: {
        buttons: [
          {
            id: 'filters',
            title: 'Filters',
            type: 'secondary',
            icon: 'flaticon2-soft-icons',
          },
          {
            id: 'refresh',
            title: 'Refresh',
            type: 'secondary',
            icon: 'flaticon2-refresh',
          },
          {
            id: 'create',
            title: 'Add new api key',
            icon: 'flaticon2-add',
          },
        ]
      }
    },
    app_users_password: {
      page: {
        title: 'Change user password',
        desc: '',
      }
    },

    dashboard: {
      page: {
        title: 'Dashboard',
        desc: 'Latest updates and statistic charts',
      }
    },
    users: {
      list: {
        page: {
          title: 'Users',
          desc: 'List of registered users',
        },
        subheader: {
          buttons: [
            {
              id: 'filters',
              title: 'Filters',
              type: 'secondary',
              icon: 'flaticon2-soft-icons',
            },
            {
              id: 'refresh',
              title: 'Refresh',
              type: 'secondary',
              icon: 'flaticon2-refresh',
            },
            {
              id: 'create',
              title: 'Add new user',
              icon: 'flaticon2-add',
            },
          ]
        }
      },
      add: {
        page: {
          title: 'Add new user',
          desc: '',
        }
      },
    },
    brokers: {
      list: {
        page: {
          title: 'Brokers',
          desc: 'List of registered brokers',
        },
        subheader: {
          buttons: [
            {
              id: 'filters',
              title: 'Filters',
              type: 'secondary',
              icon: 'flaticon2-soft-icons',
            },
            {
              id: 'refresh',
              title: 'Refresh',
              type: 'secondary',
              icon: 'flaticon2-refresh',
            },
            {
              id: 'create',
              title: 'Add new broker ',
              icon: 'flaticon2-add',
            },
          ]
        }
      },
      add: {
        page: {
          title: 'Add new broker',
          desc: '',
        }
      },
    },
    gateways: {
      list: {
        page: {
          title: 'Gateways',
          desc: 'List of registered gateways',
        },
        subheader: {
          buttons: [
            {
              id: 'filters',
              title: 'Filters',
              type: 'secondary',
              icon: 'flaticon2-soft-icons',
            },
            {
              id: 'refresh',
              title: 'Refresh',
              type: 'secondary',
              icon: 'flaticon2-refresh',
            },
            {
              id: 'create',
              title: 'Add new gateway',
              icon: 'flaticon2-add',
            },
          ]
        }
      },
      add: {
        page: {
          title: 'Add new gateway',
          desc: '',
        }
      },
    },
    locks: {
      list: {
        page: {
          title: 'Locks',
          desc: 'List of registered locks',
        },
        subheader: {
          buttons: [
            {
              id: 'filters',
              title: 'Filters',
              type: 'secondary',
              icon: 'flaticon2-soft-icons',
            },
            {
              id: 'refresh',
              title: 'Refresh',
              type: 'secondary',
              icon: 'flaticon2-refresh',
            },
            {
              id: 'create',
              title: 'Add new lock',
              icon: 'flaticon2-add',
            },
            {
              id: 'claim',
              title: 'Claim new lock',
              icon: 'flaticon2-add',
            },
          ]
        }
      },
      add: {
        page: {
          title: 'Add new lock',
          desc: '',
        }
      },
    },
    'device-types': {
      list: {
        page: {
          title: 'Device types',
          desc: '',
        },
        subheader: {
          buttons: [
            {
              id: 'filters',
              title: 'Filters',
              type: 'secondary',
              icon: 'flaticon2-soft-icons',
            },
            {
              id: 'refresh',
              title: 'Refresh',
              type: 'secondary',
              icon: 'flaticon2-refresh',
            },
            {
              id: 'create',
              title: 'Add new type',
              icon: 'flaticon2-add',
            },
          ]
        }
      },
      add: {
        page: {
          title: 'Add new type',
          desc: '',
        }
      },
    },
    firmwares: {
      list: {
        page: {
          title: 'Firmwares',
          desc: '',
        },
        subheader: {
          buttons: [
            {
              id: 'filters',
              title: 'Filters',
              type: 'secondary',
              icon: 'flaticon2-soft-icons',
            },
            {
              id: 'refresh',
              title: 'Refresh',
              type: 'secondary',
              icon: 'flaticon2-refresh',
            },
            {
              id: 'create',
              title: 'Add new firmware',
              icon: 'flaticon2-add',
            },
          ]
        }
      },
      add: {
        page: {
          title: 'Add new firmware',
          desc: '',
        }
      },
    },
    'upgrade-tasks': {
      list: {
        page: {
          title: 'Upgrade tasks',
          desc: '',
        },
        subheader: {
          buttons: [
            {
              id: 'filters',
              title: 'Filters',
              type: 'secondary',
              icon: 'flaticon2-soft-icons',
            },
            {
              id: 'refresh',
              title: 'Refresh',
              type: 'secondary',
              icon: 'flaticon2-refresh',
            },
          ]
        }
      },
    },
    settings: {
      list: {
        page: {
          title: 'Settings',
          desc: '',
        },
        subheader: {
          buttons: [
            {
              id: 'refresh',
              title: 'Refresh',
              type: 'secondary',
              icon: 'flaticon2-refresh',
            },
          ]
        }
      },
    },
  };

  public get configs(): any {
    return this.defaults;
  }
}
