import {Injectable} from '@angular/core';
import {BrokerDataService} from '@app/core/store/dataservices';
import {BrokerService} from '@app/core/store/services';
import {EntityAction, EntityOp} from '@ngrx/data';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, exhaustMap, map} from 'rxjs/operators';

@Injectable()
export class BrokersEffects {
  @Effect({dispatch: true})
  brokerAttachToOwner$ = this.actions$.pipe(
    ofType(`[Broker] @app/broker/add-to-owner`),
    exhaustMap((action: EntityAction) => {
      return this.brokerDataService.attachToOwner(action.payload.data).pipe(
        map((broker) => {
          const changes = {...action.payload.data.changes, user: broker?.user};

          return this.brokerService.createEntityAction(EntityOp.SAVE_UPDATE_ONE_SUCCESS, {
            ...{...action.payload.data, changes},
            changed: true
          }, {correlationId: action.payload.correlationId});
        }),
        catchError(error => of(this.brokerService.createEntityAction(EntityOp.SAVE_UPDATE_ONE_ERROR, {
          error: error,
          originalAction: action
        }, {correlationId: action.payload.correlationId})))
      );
    }),
  );

  @Effect({dispatch: true})
  brokerClear$ = this.actions$.pipe(
    ofType(`[Broker] @app/broker/clear`),
    exhaustMap((action: EntityAction) => {
      return this.brokerDataService.clear(action.payload.data).pipe(
        map(() => this.brokerService.createEntityAction(EntityOp.SAVE_UPDATE_ONE_SUCCESS, {
          ...action.payload.data,
          changed: true
        }, {correlationId: action.payload.correlationId})),
        catchError(error => of(this.brokerService.createEntityAction(EntityOp.SAVE_UPDATE_ONE_ERROR, {
          error: error,
          originalAction: action
        }, {correlationId: action.payload.correlationId})))
      );
    }),
  );

  @Effect({dispatch: true})
  brokerClaim$ = this.actions$.pipe(
    ofType(`[Broker] @app/broker/claim`),
    exhaustMap((action: EntityAction) => {
      return this.brokerDataService.claim(action.payload.data).pipe(
        map((broker) => {
          const changes = {...action.payload.data.changes, claimed: broker.claimed};

          return this.brokerService.createEntityAction(EntityOp.SAVE_UPDATE_ONE_SUCCESS, {
            ...{...action.payload.data, changes},
            changed: true
          }, {correlationId: action.payload.correlationId});
        }),
        catchError(error => of(this.brokerService.createEntityAction(EntityOp.SAVE_UPDATE_ONE_ERROR, {
          error: error,
          originalAction: action
        }, {correlationId: action.payload.correlationId})))
      );
    }),
  );

  constructor(
    private actions$: Actions,
    private brokerService: BrokerService,
    private brokerDataService: BrokerDataService
  ) {
  }
}
