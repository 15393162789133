import {FormBuilder} from '@angular/forms';
import {AbstractForm} from '@gatman/ngx-forms';

export class GlobalSearchForm extends AbstractForm {
  buildForm(builder: FormBuilder): void {
    this.addControls({
      q: builder.control(null),
      page: builder.control(1),
      limit: builder.control(5),
    });
  }

  isEmpty(): boolean {
    return !this.get('q').value;
  }
}
