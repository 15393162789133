import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromAuth from '@app/core/store/reducers/auth.reducers';

export const authState = createFeatureSelector<fromAuth.State>('auth');

export const isLoggedIn = createSelector(authState, auth => auth.loggedIn);
export const isLoginInProcess = createSelector(authState, auth => auth.loginInProcess);
export const refreshToken = createSelector(authState, auth => auth.refreshToken);
export const isLoggedOut = createSelector(isLoggedIn, loggedIn => !loggedIn);
export const currentUser = createSelector(authState, auth => auth.user);
// export const isSuperAdmin = createSelector(currentUser, user => user && !!user.roles.find(role => role.id === 2));
// export const visitorManagedCompanyIds = createSelector(currentUser, user => user ? user.info.managed_companies : []);
