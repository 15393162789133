import {Component, EventEmitter, Input, Output} from '@angular/core';

export interface ISearchResult {
  title: string;
  items: {icon: string, img: string, title: string, text: string, routerLink: string[]}[];
}

@Component({
  selector: 'kt-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss']
})
export class SearchResultComponent {
  // Public properties
  @Input() data: ISearchResult[];
  @Input() noRecordText: string;

  @Output() itemClick = new EventEmitter();
}
