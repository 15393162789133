import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {CoreModule} from '@app/core/core.module';
import {LayoutEffects} from '@app/core/store/effects/layout.effects';
import {BaseTableComponent} from '@app/views/partials/content/crud/base-table/base-table.component';
import {BrokersSelectComponent} from '@app/views/partials/content/general/brokers-select/brokers-select.component';
import {DeviceTypesSelectComponent} from '@app/views/partials/content/general/device-types-select/device-types-select.component';
import {DotBadgeComponent} from '@app/views/partials/content/general/dot-badge/dot-badge.component';
import {FirmwaresSelectComponent} from '@app/views/partials/content/general/firmware-select/firmwares-select.component';
import {GatewaysSelectComponent} from '@app/views/partials/content/general/gateways-select/gateways-select.component';
import {RolesSelectComponent} from '@app/views/partials/content/general/roles-select/roles-select.component';
import {UsersSelectComponent} from '@app/views/partials/content/general/users-select/users-select.component';
import {ValidationRulesComponent} from '@app/views/partials/content/general/validation-rules/validation-rules.component';
import {QuickActionComponent} from '@app/views/partials/layout/topbar/quick-action/quick-action.component';
import {MaterialModule} from '@app/views/theme/material.module';
import {NgbDropdownModule, NgbNavModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {NgSelectModule} from '@ng-select/ng-select';
import {EffectsModule} from '@ngrx/effects';
// SVG inline
import {InlineSVGModule} from 'ng-inline-svg';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {
  ActionNotificationComponent,
  AlertComponent,
  DeleteEntityDialogComponent,
  FetchEntityDialogComponent,
  UpdateStatusDialogComponent,
} from './content/crud';
// Errpr
import {ErrorComponent} from './content/general/error/error.component';
// General
import {NoticeComponent} from './content/general/notice/notice.component';
import {PortletModule} from './content/general/portlet/portlet.module';
import {
  ContextMenu2Component,
  ContextMenuComponent,
  LanguageSelectorComponent,
  NotificationComponent,
  QuickPanelComponent,
  ScrollTopComponent,
  SearchDefaultComponent,
  SearchDropdownComponent,
  SearchResultComponent,
  SplashScreenComponent,
  UserProfileComponent,
} from './layout';

@NgModule({
  declarations: [
    ScrollTopComponent,
    NoticeComponent,
    ActionNotificationComponent,
    DeleteEntityDialogComponent,
    FetchEntityDialogComponent,
    UpdateStatusDialogComponent,
    AlertComponent,

    // topbar components
    ContextMenu2Component,
    ContextMenuComponent,
    QuickPanelComponent,
    ScrollTopComponent,
    SearchResultComponent,
    SplashScreenComponent,
    LanguageSelectorComponent,
    NotificationComponent,
    SearchDefaultComponent,
    SearchDropdownComponent,
    UserProfileComponent,
    QuickActionComponent,

    ErrorComponent,

    BaseTableComponent,
    DotBadgeComponent,

    RolesSelectComponent,
    UsersSelectComponent,
    BrokersSelectComponent,
    GatewaysSelectComponent,
    DeviceTypesSelectComponent,
    FirmwaresSelectComponent,
    ValidationRulesComponent
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,

    PortletModule,

    ScrollTopComponent,
    NoticeComponent,
    ActionNotificationComponent,
    DeleteEntityDialogComponent,
    FetchEntityDialogComponent,
    UpdateStatusDialogComponent,
    AlertComponent,

    // topbar components
    ContextMenu2Component,
    ContextMenuComponent,
    QuickPanelComponent,
    ScrollTopComponent,
    SearchResultComponent,
    SplashScreenComponent,
    LanguageSelectorComponent,
    NotificationComponent,
    SearchDefaultComponent,
    SearchDropdownComponent,
    UserProfileComponent,
    QuickActionComponent,

    ErrorComponent,

    BaseTableComponent,
    DotBadgeComponent,

    RolesSelectComponent,
    UsersSelectComponent,
    ValidationRulesComponent,
    BrokersSelectComponent,
    GatewaysSelectComponent,
    DeviceTypesSelectComponent,
    FirmwaresSelectComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    InlineSVGModule,
    PortletModule,
    MaterialModule,

    // ng-bootstrap modules
    NgbDropdownModule,
    NgbNavModule,
    NgbTooltipModule,
    CoreModule,

    EffectsModule.forFeature([LayoutEffects]),
    NgSelectModule,
  ],
})
export class PartialsModule {
}
