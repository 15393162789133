export const LOGIN_URL = `/auth/login_check`;
export const REFRESH_TOKEN_URL = `/auth/token/refresh`;
export const USER_PROFILE_URL = `/profile`;
export const BROKERS_GET_URL = `/brokers`;
export const GATEWAYS_GET_URL = `/gateways`;
export const LOCKS_GET_URL = `/locks`;
export const DEVICE_TYPE_GET_URL = `/device-types`;
export const FIRMWARES_GET_URL = `/firmwares`;
export const UPGRADE_TASK_GET_URL = `/upgrade-tasks`;
export const SETTINGS_GET_URL = `/settings`;
export const API_KEY_GET_URL = `/api-keys`;
export const USERS_GET_URL = `/users`;
export const BATCH_POST_URL = `/batch`;
