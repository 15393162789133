import {StorageService} from '@app/core/services';
import {EntityCollectionReducerMethodMap, EntityDefinitionService} from '@ngrx/data';
import {Injectable} from '@angular/core';
import {AdditionalEntityCollectionReducerMethods} from '@app/core/store/reducers/additional-entity-collection-reducer-methods';

@Injectable()
export class AdditionalEntityCollectionReducerMethodsFactory {
    constructor(
      private storage: StorageService,
      private entityDefinitionService: EntityDefinitionService,
    ) {
    }

    /** Create the  {EntityCollectionReducerMethods} for the named entity type */
    create<T>(entityName: string): EntityCollectionReducerMethodMap<T> {
        const definition = this.entityDefinitionService.getDefinition<T>(entityName);
        const methodsClass = new AdditionalEntityCollectionReducerMethods<T>(entityName, definition, this.storage);
        return methodsClass.methods;
    }
}
