import {Injectable} from '@angular/core';
import {LockDataService} from '@app/core/store/dataservices';
import {LockService} from '@app/core/store/services';
import {EntityAction, EntityOp} from '@ngrx/data';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, exhaustMap, map, mergeMap} from 'rxjs/operators';

@Injectable()
export class LocksEffects {
  lockClaim$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(`[Lock] @app/lock/claim`),
      exhaustMap((action: EntityAction) => {
        return this.lockDataService.claim(action.payload.data).pipe(
          map((lock) => {
            const changes = {
              ...action.payload.data.changes,
              ...lock
            };

            return this.lockService.createEntityAction(EntityOp.SAVE_UPDATE_ONE_SUCCESS, {
              ...{...action.payload.data, id: changes.id, changes},
              changed: true
            }, {correlationId: action.payload.correlationId});
          }),
          catchError(error => of(this.lockService.createEntityAction(EntityOp.SAVE_UPDATE_ONE_ERROR, {
            error: error,
            originalAction: action
          }, {correlationId: action.payload.correlationId})))
        );
      }),
    );
  }, {dispatch: true});

  lockUpgrade$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(`[Lock] @app/lock/upgrade`),
      mergeMap((action: EntityAction) => {
        return this.lockDataService.upgrade(action.payload.data).pipe(
          map(() => {
            return this.lockService.createEntityAction(EntityOp.SAVE_UPDATE_ONE_SUCCESS, {
              ...action.payload.data,
              changed: true
            }, {correlationId: action.payload.correlationId});
          }),
          catchError(error => of(this.lockService.createEntityAction(EntityOp.SAVE_UPDATE_ONE_ERROR, {
            error: error,
            originalAction: action
          }, {correlationId: action.payload.correlationId})))
        );
      }),
    );
  }, {dispatch: true});

  lockRefreshFirmware$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(`[Lock] @app/lock/refresh-firmware`),
      exhaustMap((action: EntityAction) => {
        return this.lockDataService.refreshFirmware(action.payload.data.id).pipe(
          map((lock) => {
            const changes = {
              ...lock
            };

            return this.lockService.createEntityAction(EntityOp.SAVE_UPDATE_ONE_SUCCESS, {
              ...{id: changes.id, changes},
              changed: true
            }, {correlationId: action.payload.correlationId});
          }),
          catchError(error => of(this.lockService.createEntityAction(EntityOp.SAVE_UPDATE_ONE_ERROR, {
            error: error,
            originalAction: action
          }, {correlationId: action.payload.correlationId})))
        );
      }),
    );
  }, {dispatch: true});

  lockClear$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(`[Lock] @app/lock/clear`),
      exhaustMap((action: EntityAction) => {
        return this.lockDataService.clear(action.payload.data).pipe(
          map((lock: any) => {
              const changes = {
                ...action.payload.data.changes,
                ...lock
              };

              return this.lockService.createEntityAction(EntityOp.SAVE_UPDATE_ONE_SUCCESS, {
                ...{...action.payload.data, id: changes.id, changes},
                changed: true
              }, {correlationId: action.payload.correlationId});
            }
          ),
          catchError(error => of(this.lockService.createEntityAction(EntityOp.SAVE_UPDATE_ONE_ERROR, {
            error: error,
            originalAction: action
          }, {correlationId: action.payload.correlationId})))
        );
      }),
    );
  }, {dispatch: true});

  constructor(
    private actions$: Actions,
    private lockService: LockService,
    private lockDataService: LockDataService
  ) {
  }
}
