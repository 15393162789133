export class MenuConfig {
  public defaults: any = {
    header: {
      self: {},
      items: []
    },
    aside: {
      self: {},
      items: [
        {
          title: 'Dashboard',
          root: true,
          icon: 'fas fa-layer-group',
          page: '/dashboard'
        },
        {separator: true},
        {
          title: 'Brokers',
          root: true,
          icon: 'fas fa-stream',
          page: '/brokers'
        },
        {
          title: 'Gateways',
          root: true,
          icon: 'fas fa-hdd',
          page: '/gateways'
        },
        {
          title: 'Locks',
          root: true,
          icon: 'fas fa-door-closed',
          bullet: 'dot',
          submenu: [
            {
              title: 'Locks',
              page: '/locks',
            },
            {
              title: 'Device types',
              page: '/device-types'
            },
            {
              title: 'Firmwares',
              page: '/firmwares'
            }
          ]
        },

        {separator: true},

        {section: 'Users'},
        {
          title: 'Users',
          root: true,
          icon: 'fas fa-users',
          page: '/users'
        },
        /*{
          title: 'Roles',
          root: true,
          icon: 'flaticon-symbol',
          page: '/roles'
        },*/

        {section: 'Administration'},
        {
          title: 'Upgrade tasks',
          root: true,
          icon: 'fas fa-tasks',
          page: '/upgrade-tasks'
        },
        {
          title: 'Settings',
          root: true,
          icon: 'fas fa-cogs',
          page: '/settings'
        },
      ]
    },
  };

  public get configs(): any {
    return this.defaults;
  }
}
