import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {LayoutConfigService, SubheaderService} from '../../../core/services';
import * as objectPath from 'object-path';

@Component({
  selector: 'kt-subheader',
  templateUrl: './subheader.component.html',
  styleUrls: ['./subheader.component.scss']
})
export class SubheaderComponent implements OnInit, OnDestroy, AfterViewInit {
  // Public properties
  fluid: boolean;
  clear: boolean;

  today: number = Date.now();

  buttons: any[] = [];

  // Private properties
  private subscriptions: Subscription[] = [];

  constructor(
    public subheaderService: SubheaderService,
    private layoutConfigService: LayoutConfigService
  ) {
    const config = this.layoutConfigService.getConfig();

    this.fluid = objectPath.get(config, 'subheader.width') === 'fluid';
    this.clear = objectPath.get(config, 'subheader.clear');
  }

  ngOnInit(): void {
    this.subscriptions.push(this.subheaderService.buttons$.subscribe(bt => {
      // breadcrumbs title sometimes can be undefined
      if (bt) {
        Promise.resolve(null).then(() => {
          this.buttons = bt;
        });
      }
    }));
  }

  ngAfterViewInit(): void {
  }

  handlePageButtonClick(button, $event: MouseEvent): void {
    this.subheaderService.buttonsClick$.next({id: button.id, $event: $event});
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }
}
