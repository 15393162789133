import {StorageService} from '@app/core/services';
import {EntityAction, EntityCollection, EntityCollectionReducerMethods, EntityDefinition} from '@ngrx/data';

export class AdditionalEntityCollectionReducerMethods<T> extends EntityCollectionReducerMethods<T> {
  constructor(
    public entityName: string,
    public definition: EntityDefinition<T>,
    private storage: StorageService
  ) {
    super(entityName, definition);
  }

  protected queryLoad(collection: EntityCollection<T>): EntityCollection<T> {
    return super.queryLoad(collection);
  }

  protected queryMany(collection: EntityCollection<T>, action: EntityAction<any>): EntityCollection<T> {
    let ec: any = super.queryMany(collection, action);

    ec = {
      ...ec,
      lastQuery: action.payload.data
    };

    this.storage.setItem(`${this.entityName}.lastQuery`, JSON.stringify(action.payload.data));

    return ec;
  }

  protected queryManySuccess(collection: EntityCollection<T>, action: EntityAction<T[]>): EntityCollection<T> {
    if (action.payload.skip) {
      return collection;
    }
    let ec: any = super.queryLoadSuccess(collection, action);

    if ((action.payload as any).meta) {
      const data: any[] = (action.payload as any).data;
      const ids = [];
      const entities = {};

      data.forEach(value => {
        ids.push(value.id);
        entities[value.id] = value;
      });

      ec = {
        ...ec,
        // ids: ids,
        // entities: entities,
        showInitWaitingMessage: false,
        totalCount: (action.payload as any).meta.pagination.total
      };
    }

    return ec;
  }
}
