import {Injectable} from '@angular/core';
import {BATCH_POST_URL} from '@app/config/api-routes.config';
import {User} from '@app/core/models';
import {
  ChangeSet,
  ChangeSetItem,
  changeSetItemFactory as cif,
  CorrelationIdGenerator,
  EntityAction,
  EntityActionOptions,
  EntityCacheDispatcher,
  EntityCollectionServiceBase,
  EntityCollectionServiceElementsFactory,
  EntityOp,
  QueryParams
} from '@ngrx/data';
import {Actions} from '@ngrx/effects';
import {Observable, of, throwError} from 'rxjs';
import {distinctUntilChanged, filter, switchMap} from 'rxjs/operators';
import {ConfigService} from '@app/core/services/config.service';

@Injectable({providedIn: 'root'})
export class UserService extends EntityCollectionServiceBase<User> {
  public lastQuery: any;
  protected defaultQueryParams: QueryParams = {
    page: '1',
    limit: '20',
    include: '',
    sort: 'createdAt',
    order: 'desc',
  };

  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private entityCacheDispatcher: EntityCacheDispatcher,
    private actions$: Actions,
    private correlationIdGenerator: CorrelationIdGenerator,
    private config: ConfigService
  ) {
    super('User', serviceElementsFactory);

    (this.selectors$ as any).lastQuery$.subscribe((params) => this.lastQuery = params);
  }

  getWithQuery(queryParams?: QueryParams | any, options?: EntityActionOptions): Observable<User[]> {
    queryParams = {...this.defaultQueryParams, ...this.lastQuery, ...(queryParams ? queryParams : {})};

    return super.getWithQuery(queryParams, options);
  }

  delete(entity: any, options?: EntityActionOptions): Observable<number | string> {
    return super.delete(entity, {...options, isOptimistic: false});
  }

  batchDelete(ids: any[]): Observable<ChangeSet> {
    const changes: ChangeSetItem[] = [
      cif.delete('User', ids)
    ];

    return this.entityCacheDispatcher.saveEntities(changes, `${this.config.get('api.baseUrl')}${BATCH_POST_URL}`);
  }

  changePassword(entity: User): Observable<User> {
    const correlationId = this.correlationIdGenerator.next();

    this.store.dispatch({
      type: '[User] @app/user/change-password',
      payload: {
        data: {
          id: entity.id,
          changes: {
            id: entity.id,
            password: (entity as any).password
          }
        },
        entityName: 'User',
        isOptimistic: false,
        correlationId: correlationId
      }
    });

    return this.actions$.pipe(
      filter((action: EntityAction) => action.payload.correlationId === correlationId),
      switchMap(action => {
        if (action.payload.entityOp === EntityOp.SAVE_UPDATE_ONE_ERROR) {
          return throwError(action.payload.data.error);
        } else {
          return of(action.payload.data);
        }
      })
    );
  }

  selectEntity(key: any): Observable<User> {
    return this.entityMap$.pipe(
      switchMap(entities => entities.hasOwnProperty(key) ? of(entities[key]) : this.getByKey(key)),
      distinctUntilChanged(),
    );
  }
}
