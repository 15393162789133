import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router} from '@angular/router';
import {merge} from 'lodash';
import * as objectPath from 'object-path';
import {Subject} from 'rxjs';
import {filter, map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class PageConfigService {
  // Public properties
  onConfigUpdated$: Subject<any>;
  pageConfig: any;

  activatedRouteSnapshot: ActivatedRouteSnapshot;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    // register on config changed event and set default config
    this.onConfigUpdated$ = new Subject();

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }

          return route;
        }),
      )
      .subscribe(x => this.activatedRouteSnapshot = x.snapshot);
  }

  /**
   * Get current page config based on route
   */
  getCurrentPageConfig(path?: string): any {
    let configPath = this.cleanUrl(this.router.url);
    if (this.activatedRouteSnapshot?.data?.route_name) {
      configPath = this.activatedRouteSnapshot.data.route_name;
    }

    if (path) {
      configPath += '.' + path;
    }

    // get page config by path
    return objectPath.get(this.pageConfig, configPath);
  }

  /**
   * Set existing config with a new value
   */
  setConfig(value: any, save?: boolean): void {
    this.pageConfig = merge(this.pageConfig, value);

    if (save) {
      // not implemented
    }

    // fire off an event that all subscribers will listen
    this.onConfigUpdated$.next(this.pageConfig);
  }

  /**
   * Load confgis
   */
  loadConfigs(config: any): void {
    this.pageConfig = config;
    this.onConfigUpdated$.next(this.pageConfig);
  }

  /**
   * Remove unnecessary params from URL
   */
  cleanUrl(url: string): string {
    if (url.charAt(0) === '/') {
      url = url.substr(1);
    }

    // we get the page title from config, using url path.
    // we need to remove query from url ?id=1 before we use the path to search in array config.
    let finalUrl = url.replace(/\//g, '.');
    if (finalUrl.indexOf('?') !== -1) {
      finalUrl = finalUrl.substring(0, finalUrl.indexOf('?'));
    }

    return finalUrl;
  }
}
