import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '@app/core/guard';
import {BaseComponent} from './views/theme/base/base.component';

const routes: Routes = [
  {
    path: 'auth',
    // canActivate: [NoAuthGuard],
    loadChildren: () => import('app/views/pages/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('app/views/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'users',
        loadChildren: () => import('app/views/pages/users/users.module').then(m => m.UsersModule)
      },
      {
        path: 'brokers',
        loadChildren: () => import('app/views/pages/brokers/brokers.module').then(m => m.BrokersModule)
      },
      {
        path: 'gateways',
        loadChildren: () => import('app/views/pages/gateways/gateways.module').then(m => m.GatewaysModule)
      },
      {
        path: 'locks',
        loadChildren: () => import('app/views/pages/locks/locks.module').then(m => m.LocksModule)
      },
      {
        path: 'device-types',
        loadChildren: () => import('app/views/pages/device-types/device-types.module').then(m => m.DeviceTypesModule)
      },
      {
        path: 'firmwares',
        loadChildren: () => import('app/views/pages/firmwares/firmwares.module').then(m => m.FirmwaresModule)
      },
      {
        path: 'upgrade-tasks',
        loadChildren: () => import('app/views/pages/upgrade-tasks/upgrade-tasks.module').then(m => m.UpgradeTasksModule)
      },
      {
        path: 'settings',
        loadChildren: () => import('app/views/pages/settings/settings.module').then(m => m.SettingsModule)
      },
      /*{
          path: 'error/403',
          component: ErrorPageComponent,
          data: {
              'type': 'error-v6',
              'code': 403,
              'title': '403... Access forbidden',
              'desc': 'Looks like you don\'t have permission to access for requested page.<br> Please, contact administrator'
          }
      },
      {path: 'error/:type', component: ErrorPageComponent},*/
      {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
      {path: '**', redirectTo: 'dashboard', pathMatch: 'full'}
    ]
  },

  {path: '**', redirectTo: 'error/403', pathMatch: 'full'},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
