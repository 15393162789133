import {HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {REFRESH_TOKEN_URL} from '@app/config/api-routes.config';
import * as AuthActions from '@app/core/store/actions/auth.actions';
import {AppState} from '@app/core/store/reducers';
import {refreshToken} from '@app/core/store/selectors/auth.selectors';
import {Store} from '@ngrx/store';
import {Observable, of, Subject, Subscription, throwError} from 'rxjs';
import {catchError, switchMap, take} from 'rxjs/operators';
import {ConfigService} from '@app/core/services/config.service';

@Injectable({providedIn: 'root'})
export class RefreshTokenInterceptor implements HttpInterceptor, OnDestroy {
  private isTokenRefreshed = new Subject<string>();
  private accessToken$: Subscription;

  constructor(private store: Store<AppState>, private http: HttpClient, private config: ConfigService) {
  }

  private _isRefreshingToken = false;

  get isRefreshingToken(): boolean {
    return this._isRefreshingToken;
  }

  set isRefreshingToken(value: boolean) {
    this._isRefreshingToken = value;
  }

  private _accessToken: string;

  get accessToken(): string {
    return this._accessToken;
  }

  set accessToken(value: string) {
    this._accessToken = value;
  }

  ngOnDestroy(): void {
    if (this.accessToken$) {
      this.accessToken$.unsubscribe();
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401 && err.error.message === 'Expired JWT Token') {
          if (this.isRefreshingToken) {
            return this.cacheRequestUntilTokenRefreshed(req, next);
          }

          this.isRefreshingToken = true;

          return this.store.select(refreshToken)
            .pipe(
              take(1),
              switchMap(token => this.http.post(`${this.config.get('api.baseUrl')}${REFRESH_TOKEN_URL}`, {refresh_token: token})),
              switchMap((response: any) => {
                this.store.dispatch(AuthActions.handleSuccessfulRefreshToken({...response}));

                this.accessToken = response.token;
                this.isRefreshingToken = false;
                this.isTokenRefreshed.next();

                const request = req.clone({
                  setHeaders: {
                    Authorization: 'Bearer ' + this.accessToken
                  }
                });

                return next.handle(request);
              }),
              catchError(error => {
                this.store.dispatch(AuthActions.handleFailedRefreshToken({error}));

                return of();
              })
            );
        }

        return throwError(err);
      })
    );
  }

  cacheRequestUntilTokenRefreshed(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return this.isTokenRefreshed
      .pipe(
        take(1),
        switchMap(() => {
          const request = req.clone({
            setHeaders: {
              Authorization: 'Bearer ' + this.accessToken
            }
          });

          return next.handle(request);
        })
      );
  }
}
