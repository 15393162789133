import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {AuthNoticeService, StorageService} from '@app/core/services';
import * as AuthActions from '@app/core/store/actions/auth.actions';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {TranslateService} from '@ngx-translate/core';
import {of} from 'rxjs';
import {catchError, exhaustMap, map, tap} from 'rxjs/operators';
import {LOGIN_URL, USER_PROFILE_URL} from '@app/config/api-routes.config';
import {ConfigService} from '@app/core/services/config.service';

@Injectable()
export class AuthEffects {
  @Effect()
  login$ = this.actions$.pipe(
    ofType(AuthActions.ActionTypes.Login),
    tap(() => this.authNoticeService.setNotice(null)),
    exhaustMap(({email, password}) => this.http.post(`${this.configService.get('api.baseUrl')}${LOGIN_URL}`, {
        username: email,
        password
      }).pipe(
        map((response: any) => AuthActions.handleSuccessfulLogin(response)),
        catchError(error => of(AuthActions.handleFailedLogin({error})))
      )
    )
  );

  @Effect()
  loginSuccess$ = this.actions$.pipe(
    ofType(AuthActions.ActionTypes.HandleSuccessfulLogin),
    exhaustMap(({token, refresh_token}) => {
      this.storage.setItem('accessToken', token);
      this.storage.setItem('refreshToken', refresh_token);

      return this.http.get(`${this.configService.get('api.baseUrl')}${USER_PROFILE_URL}`).pipe(
        map((response: any) => AuthActions.loadProfile({user: response.data})),
        catchError(error => of(AuthActions.handleFailedLogin({error})))
      );
    })
  );

  @Effect({dispatch: false})
  refreshTokenSuccess$ = this.actions$.pipe(
    ofType(AuthActions.ActionTypes.HandleSuccessfulRefreshToken),
    tap(({token, refresh_token}) => {
      this.storage.setItem('accessToken', token);
      this.storage.setItem('refreshToken', refresh_token);
    })
  );
  @Effect({dispatch: false})
  loadProfile$ = this.actions$.pipe(
    ofType(AuthActions.ActionTypes.LoadProfile),
    map(({user}) => {
      this.storage.setItem('user', JSON.stringify(user));
    }),
    tap(() => this.router.navigateByUrl('/dashboard'))
  );
  @Effect({dispatch: false})
  loginFailure$ = this.actions$.pipe(
    ofType(AuthActions.ActionTypes.HandleFailedLogin),
    tap(() => {
      this.storage.clear();
      this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
    })
  );
  private returnUrl: string;
  @Effect({dispatch: false})
  refreshTokenFailed$ = this.actions$.pipe(
    ofType(AuthActions.ActionTypes.HandleFailedRefreshToken),
    tap(_ => {
      this.storage.clear();
      this.authNoticeService.setNotice(null);
      this.router.navigate(['/auth/login'], {queryParams: {returnUrl: this.returnUrl}}).then();
    })
  );
  @Effect({dispatch: false})
  logout$ = this.actions$.pipe(
    ofType(AuthActions.ActionTypes.Logout),
    tap(() => {
      this.storage.clear();
      this.authNoticeService.setNotice(null);
      this.router.navigate(['/auth/login'], {queryParams: {returnUrl: this.returnUrl}}).then();
    })
  );

  constructor(
    private actions$: Actions,
    private storage: StorageService,
    private router: Router,
    private http: HttpClient,
    private authNoticeService: AuthNoticeService,
    private translate: TranslateService,
    private configService: ConfigService
  ) {

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.returnUrl = event.url;
      }
    });
  }
}
