import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {isLoggedIn} from '@app/core/store/selectors/auth.selectors';
import {AppState} from '@app/core/store/reducers';

@Injectable({providedIn: 'root'})
export class NoAuthGuard implements CanActivate {
    private store: Store<AppState>;
    private router: Router;

    constructor(store: Store<AppState>, router: Router) {
        this.store = store;
        this.router = router;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.store
            .pipe(
                select(isLoggedIn),
                tap(loggedIn => {
                    if (loggedIn) {
                        this.router.navigateByUrl('/dashboard').then();
                    }
                })
            );
    }
}
