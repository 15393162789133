import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {CoreModule} from '@app/core/core.module';
import {LayoutEffects} from '@app/core/store/effects/layout.effects';
import {MaterialModule} from '@app/views/theme/material.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {EffectsModule} from '@ngrx/effects';
import {LoadingBarModule} from '@ngx-loading-bar/core';
import {TranslateModule} from '@ngx-translate/core';
import {InlineSVGModule} from 'ng-inline-svg';
import {NgxDaterangepickerMd} from 'ngx-daterangepicker-material';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {NgxPermissionsModule} from 'ngx-permissions';
import {PartialsModule} from '../partials/partials.module';
import {AsideLeftComponent} from './aside/aside-left.component';
import {BaseComponent} from './base/base.component';
import {BrandComponent} from './brand/brand.component';
import {FooterComponent} from './footer/footer.component';
import {HeaderMobileComponent} from './header/header-mobile/header-mobile.component';
import {HeaderComponent} from './header/header.component';
import {MenuHorizontalComponent} from './header/menu-horizontal/menu-horizontal.component';
import {TopbarComponent} from './header/topbar/topbar.component';
import {HtmlClassService} from './html-class.service';
import {SubheaderComponent} from './subheader/subheader.component';

@NgModule({
  declarations: [
    BaseComponent,
    FooterComponent,

    // headers
    HeaderComponent,
    BrandComponent,
    HeaderMobileComponent,

    // subheader
    SubheaderComponent,

    // topbar components
    TopbarComponent,

    // aside left menu components
    AsideLeftComponent,

    // horizontal menu components
    MenuHorizontalComponent,
  ],
  exports: [
    MaterialModule,
    NgbModule,
    PerfectScrollbarModule,

    BaseComponent,
    FooterComponent,

    // headers
    HeaderComponent,
    BrandComponent,
    HeaderMobileComponent,

    // subheader
    SubheaderComponent,

    // topbar components
    TopbarComponent,

    // aside left menu components
    AsideLeftComponent,

    // horizontal menu components
    MenuHorizontalComponent,
  ],
  imports: [
    MaterialModule,
    CommonModule,
    RouterModule,
    CoreModule,
    FormsModule,
    TranslateModule.forChild(),
    LoadingBarModule,
    PartialsModule,
    InlineSVGModule,
    EffectsModule.forFeature([LayoutEffects]),

    NgxPermissionsModule.forChild(),

    PerfectScrollbarModule,

    NgxDaterangepickerMd,

    // ng-bootstrap modules
    NgbModule,
  ],
  providers: [HtmlClassService]
})
export class ThemeModule {
}
