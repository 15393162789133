import {FocusMonitor} from '@angular/cdk/a11y';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {Component, ElementRef, Input, OnDestroy, Optional, Self, ViewChild} from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormControl, FormGroup, NgControl} from '@angular/forms';
import {MatFormFieldControl} from '@angular/material/form-field';
import {Broker} from '@app/core/models';
import {EntityFilterService} from '@app/core/services';
import {BrokerDataService} from '@app/core/store/dataservices';
import {ID} from '@app/core/types';
import {NgSelectComponent} from '@ng-select/ng-select';
import {BehaviorSubject, concat, Observable, of, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, switchMap, tap} from 'rxjs/operators';

@Component({
  selector: 'app-brokers-select',
  templateUrl: 'brokers-select.component.html',
  providers: [{provide: MatFormFieldControl, useExisting: BrokersSelectComponent}],
  styleUrls: ['brokers-select.component.scss']
})
export class BrokersSelectComponent implements ControlValueAccessor, MatFormFieldControl<Broker[] | Broker>, OnDestroy {
  static nextId = 0;

  brokersCtrl: FormControl;
  stateChanges = new Subject<void>();
  focused = false;
  errorState = false;
  controlType = 'app-brokers-select';
  id = `app-brokers-select-${BrokersSelectComponent.nextId++}`;
  describedBy = '';

  searchInput$ = new BehaviorSubject<string>('');
  brokers$: Observable<Broker[]>;
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  filterForm = new FormGroup({});

  @Input() multiple: boolean;
  @Input() allowNew: boolean;
  @ViewChild(NgSelectComponent, {static: true}) ngSelectComponent: NgSelectComponent;

  constructor(
    private formBuilder: FormBuilder,
    private filterService: EntityFilterService,
    private brokerDataService: BrokerDataService,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl) {

    this.brokersCtrl = formBuilder.control(null);

    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();

      if (this.focused && !this.brokers$) {
        this.loadOptions();
      }
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  @Input()
  set filters(filters: Record<string, any>) {
    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        this.filterForm.addControl(key, this.formBuilder.control(filters[key]));
      }
    }
  }

  get empty(): boolean {
    return !this.brokersCtrl.value || this.brokersCtrl.value.length === 0;
  }

  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty || !!this.searchInput$.getValue();
  }

  private _placeholder = 'Search for brokers...';

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  private _required = false;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private _disabled = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.brokersCtrl.disable() : this.brokersCtrl.enable();
    this.stateChanges.next();
  }

  @Input()
  get value(): Broker[] | Broker {
    return this.brokersCtrl.value;
  }

  set value(value: Broker[] | Broker | null) {
    this.brokersCtrl.setValue(value);

    this.stateChanges.next();
  }

  trackByFn(item: Broker): ID {
    return item.id;
  }

  onChange = (_: any) => {
  }

  onTouched = () => {
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this.searchInput$.complete();
    this.isLoading$.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent): void {
  }

  writeValue(value: Broker[] | Broker | null): void {
    if (value) {
      this.value = value instanceof Array ? value : [value];
    } else {
      this.value = null;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  emitChangeEvent(): void {
    this.onChange(this.brokersCtrl.value);
    this.stateChanges.next();
  }

  private loadOptions(): void {
    this.brokers$ = concat(
      of([]),
      this.filterService.changes(null, null, this.filterForm, this.searchInput$).pipe(
        distinctUntilChanged(),
        debounceTime(300),
        tap(() => this.isLoading$.next(true)),
        switchMap(changes => this.brokerDataService.getWithQuery({...changes.filters, sort: 'host'})),
        map((response: any) => response.data),
        map((brokers: Broker[]) => {
          return brokers.map(broker => {
            return {...broker, label: `Host: ${broker.host} Port: ${broker.port}`};
          });
        }),
        tap(() => {
          this.isLoading$.next(false);
          this.ngSelectComponent.open();
        }),
      )
    );
  }
}
