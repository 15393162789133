import {User} from '@app/core/models';
import {createAction, props} from '@ngrx/store';

export enum ActionTypes {
  Login = '[Auth/Api] Login visitor',
  HandleFailedRefreshToken = '[Auth/Page] Refresh token invalid. Redirect to login page',
  HandleSuccessfulRefreshToken = '[Auth/Page] Save refreshed credentials into store',
  HandleSuccessfulLogin = '[Auth/Page] Save credentials into store',
  HandleFailedLogin = '[Auth/Page] Show auth error',
  LoadProfile = '[Auth/Api] Load visitor profile',
  Logout = '[Auth/Api] Logout visitor',
}

export const login = createAction(ActionTypes.Login, props<{ email: string, password: string }>());

export const handleFailedLogin = createAction(ActionTypes.HandleFailedLogin, props<{ error: any }>());
export const handleFailedRefreshToken = createAction(ActionTypes.HandleFailedRefreshToken, props<{ error: any }>());

export const handleSuccessfulLogin = createAction(ActionTypes.HandleSuccessfulLogin, props<{
  token: string,
  refresh_token: string,
}>());

export const handleSuccessfulRefreshToken = createAction(ActionTypes.HandleSuccessfulRefreshToken, props<{
  token: string,
  refresh_token: string,
}>());

export const loadProfile = createAction(ActionTypes.LoadProfile, props<{ user: User }>());

export const logout = createAction(ActionTypes.Logout);
