import {User} from '@app/core/models';
import {StorageService} from '@app/core/services';
import * as AuthActions from '@app/core/store/actions/auth.actions';
import {ActionReducer, createReducer, on} from '@ngrx/store';

export interface State {
  loginInProcess: boolean;
  forgotPasswordRequestInProcess: boolean;
  loggedIn: boolean;
  accessToken: string;
  refreshToken: string;
  user: User | null;
}

export const emptyState = {
  loginInProcess: false,
  forgotPasswordRequestInProcess: false,
  loggedIn: false,
  accessToken: null,
  refreshToken: null,
  user: null
};

export function getReducer(storage: StorageService): ActionReducer<State> {
  const initialState: State = {
    ...emptyState,
    loggedIn: !!storage.getItem('accessToken'),
    accessToken: storage.getItem('accessToken'),
    refreshToken: storage.getItem('refreshToken'),
    user: JSON.parse(storage.getItem('user')) as User
  };

  return createReducer(
    initialState,
    on(AuthActions.login, (state, {}) => {
      return {
        ...state, loginInProcess: true
      };
    }),
    on(AuthActions.handleSuccessfulLogin, (state, {token, refresh_token}) => {
      return {
        ...state, loggedIn: true, accessToken: token, refreshToken: refresh_token
      };
    }),
    on(AuthActions.handleSuccessfulRefreshToken, (state, {token, refresh_token}) => {
      return {
        ...state, accessToken: token, refreshToken: refresh_token
      };
    }),
    on(AuthActions.loadProfile, (state, {user}) => {
      return {
        ...state, loginInProcess: false, user
      };
    }),
    on(AuthActions.handleFailedLogin, () => {
      return emptyState;
    }),
    on(AuthActions.logout, () => {
      return emptyState;
    }),
  );
}
