import {Injectable} from '@angular/core';
import object from 'lodash-es/object';

@Injectable({providedIn: 'root'})
export class ConfigService {
  static config: Config;

  static loadConfig(): Promise<void> {
    return new Promise((resolve, reject) => {
      const oReq = new XMLHttpRequest();
      oReq.addEventListener('load', () => {
        if (oReq.status === 200) {
          try {
            ConfigService.config = JSON.parse(oReq.responseText);
          } catch (e) {
            reject(e);
          }
          resolve();
        } else {
          reject(oReq.statusText);
        }
      });
      oReq.open('GET', '/config/config.json');
      oReq.send();
    });
  }

  get(key, defaultValue = null): any {
    return object.get(ConfigService.config, key, defaultValue);
  }
}

interface Config {
  api: ApiConfig;
}

interface ApiConfig {
  baseUrl: string;
}
