import {StorageService} from '@app/core/services';
import {EntityMetadataMap} from '@ngrx/data';
import {Configuration} from '@app/core/models';

export function initializeEntityMetadata(storage: StorageService): EntityMetadataMap {
  // initialize app by loading default demo layout config
  return {
    Broker: {
      additionalCollectionState: {
        totalCount: 0,
        lastQuery: storage.getItem('Broker.lastQuery') ? JSON.parse(storage.getItem('Broker.lastQuery')) : {},
        showInitWaitingMessage: true,
      }
    },
    Gateway: {
      additionalCollectionState: {
        totalCount: 0,
        lastQuery: storage.getItem('Gateway.lastQuery') ? JSON.parse(storage.getItem('Gateway.lastQuery')) : {},
        showInitWaitingMessage: true,
      }
    },
    Lock: {
      additionalCollectionState: {
        totalCount: 0,
        lastQuery: storage.getItem('Lock.lastQuery') ? JSON.parse(storage.getItem('Lock.lastQuery')) : {},
        showInitWaitingMessage: true,
      }
    },
    User: {
      additionalCollectionState: {
        totalCount: 0,
        lastQuery: storage.getItem('User.lastQuery') ? JSON.parse(storage.getItem('User.lastQuery')) : {},
        showInitWaitingMessage: true,
      }
    },
    ApiKey: {
      additionalCollectionState: {
        totalCount: 0,
        lastQuery: storage.getItem('ApiKey.lastQuery') ? JSON.parse(storage.getItem('ApiKey.lastQuery')) : {},
        showInitWaitingMessage: true,
      }
    },
    DeviceType: {
      additionalCollectionState: {
        totalCount: 0,
        lastQuery: storage.getItem('DeviceType.lastQuery') ? JSON.parse(storage.getItem('DeviceType.lastQuery')) : {},
        showInitWaitingMessage: true,
      }
    },
    Firmware: {
      additionalCollectionState: {
        totalCount: 0,
        lastQuery: storage.getItem('Firmware.lastQuery') ? JSON.parse(storage.getItem('Firmware.lastQuery')) : {},
        showInitWaitingMessage: true,
      }
    },
    UpgradeTask: {
      additionalCollectionState: {
        totalCount: 0,
        lastQuery: storage.getItem('UpgradeTask.lastQuery') ? JSON.parse(storage.getItem('UpgradeTask.lastQuery')) : {},
        showInitWaitingMessage: true,
      }
    },
    Configuration: {
      selectId: (entity: Configuration) => entity.property,
      additionalCollectionState: {
        totalCount: 0,
        lastQuery: storage.getItem('Configuration.lastQuery') ? JSON.parse(storage.getItem('Configuration.lastQuery')) : {},
        showInitWaitingMessage: true,
      }
    },
  };
}
