import {Injectable} from '@angular/core';
import {DefaultPersistenceResultHandler, EntityAction} from '@ngrx/data';
import {Action} from '@ngrx/store';

@Injectable()
export class AdditionalPropertyPersistenceResultHandler extends DefaultPersistenceResultHandler {
  handleSuccess(originalAction: EntityAction): (data: any) => Action {
    const actionHandler = super.handleSuccess(originalAction);

    return function(data: any): any {
      const action: any = actionHandler.call(this, data.data ? data.data : data);

      if (action && data && data.meta) {
        (action as any).payload.meta = data.meta;
      }
      return action;
    };
  }
}
