// Angular
import { Component, OnInit } from '@angular/core';
import * as objectPath from 'object-path';
import {LayoutConfigService} from '../../../core/services';

@Component({
    selector: 'kt-footer',
    templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
    // Public properties
    today: number = Date.now();
    fluid: boolean;

    /**
     * Component constructor
     *
     * @param layoutConfigService: LayouConfigService
     */
    constructor(private layoutConfigService: LayoutConfigService) {
    }

    ngOnInit(): void {
        const config = this.layoutConfigService.getConfig();

        // footer width fluid
        this.fluid = objectPath.get(config, 'footer.self.width') === 'fluid';
    }
}
