import {ChangeDetectorRef, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {FormFactory} from '@gatman/ngx-forms';
import {GlobalSearchForm} from '@app/core/forms/global-search.form';
import {debounceTime, filter, finalize, map, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {BehaviorSubject, merge, Observable} from 'rxjs';
import {BaseComponent} from '@app/views/partials/content/general/base-component';
import {BrokerDataService, GatewayDataService, LockDataService} from '@app/core/store/dataservices';
import {Broker, Gateway, Lock} from '@app/core/models';

@Component({
  selector: 'kt-search-dropdown',
  templateUrl: './search-dropdown.component.html',
})
export class SearchDropdownComponent extends BaseComponent {
  @Input() icon = 'flaticon2-search-1';
  @Input() type: 'brand' | 'success' | 'warning' = 'brand';

  @ViewChild('searchInput', {static: false}) searchInput: ElementRef;

  data$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  result: any[];
  loading: boolean;
  searchForm: GlobalSearchForm;

  constructor(
    private cdr: ChangeDetectorRef,
    private formFactory: FormFactory,
    private brokerService: BrokerDataService,
    private gatewayService: GatewayDataService,
    private lockService: LockDataService,
  ) {
    super();

    this.searchForm = this.formFactory.create<GlobalSearchForm>(GlobalSearchForm);

    this.searchForm.valueChanges.pipe(
      takeUntil(this.destroy$),
      filter(filters => !!filters.q),
      debounceTime(500),
      finalize(() => this.loading = false),
      tap(() => {
        this.loading = true;
        this.data$.next([]);
      }),
      switchMap(filters => merge(
        this.getBrokers$(filters),
        this.getGateways$(filters),
        this.getLocks$(filters)
      ))
    ).subscribe(
      result => {
        if (result) {
          this.data$.next([...this.data$.getValue(), result]);
        }

        this.loading = false;
        this.cdr.markForCheck();
      }
    );
  }

  /**
   * Clear search
   *
   * @param e: Event
   */
  clear(e): void {
    this.data$.next([]);
    this.searchForm.get('q').setValue(null);
  }

  openChange(): void {
    setTimeout(() => this.searchInput.nativeElement.focus());
  }

  private getBrokers$(filters): Observable<any> {
    return this.brokerService.getWithQuery({...filters, include: 'user'}).pipe(
      take(1),
      map(result => result.data),
      map(data => {
        if (data.length > 0) {
          return {
            text: 'Brokers',
            items: data.map((broker: Broker) => {
              return {
                icon: `<i class="flaticon2-box ${broker.claimed ? 'kt-font-success' : 'kt-font-danger'}">`,
                title: `Host: ${broker.host} | Port: ${broker.port}`,
                text: `Owner: ${broker.user.email || '-'}`,
                routerLink: ['/brokers/list', {outlets: {view_item: [broker.id]}}]
              };
            })
          };
        }
      })
    );
  }

  private getGateways$(filters): Observable<any> {
    return this.gatewayService.getWithQuery({...filters, include: 'user,broker'}).pipe(
      take(1),
      map(result => result.data),
      map(data => {
        if (data.length > 0) {
          return {
            text: 'Gateways',
            items: data.map((gateway: Gateway) => {
              return {
                icon: `<i class="flaticon2-box ${gateway.claimed ? 'kt-font-success' : 'kt-font-danger'}">`,
                title: gateway.hostname,
                text: `Owner: ${gateway.user.email || '-'}`,
                routerLink: ['/gateways/list', {outlets: {view_item: [gateway.id]}}]
              };
            })
          };
        }
      })
    );
  }

  private getLocks$(filters): Observable<any> {
    return this.lockService.getWithQuery({...filters, include: 'user'}).pipe(
      take(1),
      map(result => result.data),
      map(data => {
        if (data.length > 0) {
          return {
            text: 'Locks',
            items: data.map((lock: Lock) => {
              return {
                icon: '<i class="flaticon2-box kt-font-success">',
                title: `MacId: ${lock.macId}`,
                text: `Owner: ${lock.user.email || '-'}`,
                routerLink: ['/locks/list', {outlets: {view_item: [lock.id]}}]
              };
            })
          };
        }
      })
    );
  }
}
