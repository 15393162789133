import {InjectionToken} from '@angular/core';
import {StorageService} from '@app/core/services';
import * as AuthActions from '@app/core/store/actions/auth.actions';
import * as AuthReducer from '@app/core/store/reducers/auth.reducers';
import {layoutReducer} from '@app/core/store/reducers/layout.reducers';
import {environment} from '@environments/environment';
import {routerReducer} from '@ngrx/router-store';
import {ActionReducerMap, MetaReducer} from '@ngrx/store';

function clearState(reducer): (state, action) => any {
  return (state, action) => {

    if (action.type === AuthActions.ActionTypes.Logout) {
      state = {
        auth: state.auth,
        layout: state.layout,
      };
    }

    return reducer(state, action);
  };
}

function entityCacheState(reducer): (state, action) => any {
  return (state, action) => {
    return reducer(state, action);
  };
}

// tslint:disable-next-line:no-empty-interface
export interface AppState {
}

export function getAppReducers(storage: StorageService): ActionReducerMap<AppState> {
  return {
    auth: AuthReducer.getReducer(storage),
    layout: layoutReducer,
    router: routerReducer,
    entityCache: entityCacheState
  };
}

export const APP_REDUCERS_TOKEN = new InjectionToken<ActionReducerMap<AppState>>('Registered Reducers');

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [clearState] : [clearState];
