import {FocusMonitor} from '@angular/cdk/a11y';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {Component, ElementRef, Input, OnDestroy, Optional, Self, ViewChild} from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormControl, FormGroup, NgControl} from '@angular/forms';
import {MatFormFieldControl} from '@angular/material/form-field';
import {Firmware} from '@app/core/models';
import {EntityFilterService} from '@app/core/services';
import {FirmwareDataService} from '@app/core/store/dataservices';
import {ID} from '@app/core/types';
import {NgSelectComponent} from '@ng-select/ng-select';
import {BehaviorSubject, concat, Observable, of, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, switchMap, tap} from 'rxjs/operators';

@Component({
  selector: 'app-firmwares-select',
  templateUrl: 'firmwares-select.component.html',
  providers: [{provide: MatFormFieldControl, useExisting: FirmwaresSelectComponent}],
  styleUrls: ['firmwares-select.component.scss']
})
export class FirmwaresSelectComponent implements ControlValueAccessor, MatFormFieldControl<Firmware[] | Firmware>, OnDestroy {
  static nextId = 0;

  firmwaresCtrl: FormControl;
  stateChanges = new Subject<void>();
  focused = false;
  errorState = false;
  controlType = 'app-firmwares-select';
  id = `app-firmwares-select-${FirmwaresSelectComponent.nextId++}`;
  describedBy = '';

  searchInput$ = new BehaviorSubject<string>('');
  firmwares$: Observable<Firmware[]>;
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  filterForm = new FormGroup({});

  @Input() multiple: boolean;
  @Input() allowNew: boolean;
  @ViewChild(NgSelectComponent, {static: true}) ngSelectComponent: NgSelectComponent;

  constructor(
    private formBuilder: FormBuilder,
    private filterService: EntityFilterService,
    private firmwareDataService: FirmwareDataService,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl) {

    this.firmwaresCtrl = formBuilder.control(null);

    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();

      if (this.focused && !this.firmwares$) {
        this.loadOptions();
      }
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  @Input()
  set filters(filters: Record<string, any>) {
    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        this.filterForm.addControl(key, this.formBuilder.control(filters[key]));
      }
    }
  }

  get empty(): boolean {
    return !this.firmwaresCtrl.value || this.firmwaresCtrl.value.length === 0;
  }

  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty || !!this.searchInput$.getValue();
  }

  private _placeholder = 'Search for firmwares...';

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  private _required = false;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private _disabled = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.firmwaresCtrl.disable() : this.firmwaresCtrl.enable();
    this.stateChanges.next();
  }

  @Input()
  get value(): Firmware[] | Firmware {
    return this.firmwaresCtrl.value;
  }

  set value(value: Firmware[] | Firmware | null) {
    if (value) {
      this.firmwaresCtrl.setValue(this.multiple ? value : value[0]);
    } else {
      this.firmwaresCtrl.setValue(null);
    }

    this.stateChanges.next();
  }

  trackByFn(item: Firmware): ID {
    return item.id;
  }

  onChange = (_: any) => {
  }

  onTouched = () => {
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this.searchInput$.complete();
    this.isLoading$.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent): void {
  }

  writeValue(value: Firmware[] | Firmware | null): void {
    if (value) {
      this.value = value instanceof Array ? value : [value];
    } else {
      this.value = null;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  emitChangeEvent(): void {
    this.onChange(this.firmwaresCtrl.value);
    this.stateChanges.next();
  }

  private loadOptions(): void {
    this.firmwares$ = concat(
      of([]),
      this.filterService.changes(null, null, this.filterForm, this.searchInput$).pipe(
        distinctUntilChanged(),
        debounceTime(300),
        tap(() => this.isLoading$.next(true)),
        switchMap(changes => this.firmwareDataService.getWithQuery({...changes.filters, sort: 'title'})),
        map((response: any) => response.data),
        tap(() => {
          this.isLoading$.next(false);
          this.ngSelectComponent.open();
        }),
      )
    );
  }
}
