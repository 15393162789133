import {Pipe, PipeTransform} from '@angular/core';
import {frequencies} from '@app/core/enums/gateway-frequency.enum';

/**
 * Returns only first letter of string
 */
@Pipe({
  name: 'frequencyDescription'
})
export class FrequencyDescription implements PipeTransform {
  transform(value: number): string | null {
    return frequencies.find((frequency) => frequency.id === value)?.description;
  }
}
