import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {USERS_GET_URL} from '@app/config/api-routes.config';
import {User} from '@app/core/models';
import {ID} from '@app/core/types';
import {TypesUtilsService} from '@app/core/utils/types-utils.service';
import {DefaultDataService, HttpUrlGenerator} from '@ngrx/data';
import {Update} from '@ngrx/entity';
import moment from 'moment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ConfigService} from '@app/core/services/config.service';

@Injectable()
export class UserDataService extends DefaultDataService<User> {
  resourceBaseUrl: string;

  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    private typesUtilsService: TypesUtilsService,
    private config: ConfigService
  ) {
    super('User', http, httpUrlGenerator);

    httpUrlGenerator.registerHttpResourceUrls({
      User: {
        entityResourceUrl: `${this.config.get('api.baseUrl')}${USERS_GET_URL}`,
        collectionResourceUrl: `${this.config.get('api.baseUrl')}${USERS_GET_URL}`,
      }
    });
  }

  getById(data: any): Observable<User> {
    if (typeof (data) === 'object') {
      return this.execute('GET', `${this.config.get('api.baseUrl')}${USERS_GET_URL}/${data.key}`, {}, {params: data.query}).pipe(
        map((response: any) => response.data),
        map(user => this.mapUser(user))
      );
    } else {
      return this.execute('GET', `${this.config.get('api.baseUrl')}${USERS_GET_URL}/${data}`).pipe(
        map((response: any) => response.data),
        map(firmware => this.mapUser(firmware))
      );
    }
  }

  getByIdWithQuery(id: ID, params: string | any): Observable<User> {
    return this.execute('GET', `${this.config.get('api.baseUrl')}${USERS_GET_URL}/${id}`, {}, {params: params}).pipe(
      map((response: any) => response.data),
      map(user => this.mapUser(user))
    );
  }

  // ToDo replace any with a proper object type
  getWithQuery(params: string | any): Observable<User[]> {
    const filters = {...params.filters};

    params = (params instanceof Object) ? {...this.typesUtilsService.objectWithoutProp(params, 'filters'), ...filters} : params;

    return this.execute('GET', `${this.config.get('api.baseUrl')}${USERS_GET_URL}`, {}, {params: params}).pipe(
      map(response => {
        const data = (response as any).data.map(user => this.mapUser(user));

        return {...response, data};
      })
    );
  }

  delete(key: number | string): Observable<number | string> {
    return this.execute('DELETE', `${this.config.get('api.baseUrl')}${USERS_GET_URL}/${key}`).pipe(
      map(() => key)
    );
  }

  update(update: Update<User>): Observable<User> {
    return this.execute('PUT', `${this.config.get('api.baseUrl')}${USERS_GET_URL}/${update.id}`, update.changes).pipe(
      map((response: any) => response.data),
      map(user => this.mapUser(user))
    );
  }

  changePassword(update: Update<User>): Observable<User> {
    return this.execute('PUT', `${this.config.get('api.baseUrl')}${USERS_GET_URL}/${update.id}/password`, update.changes).pipe(
      map((response: any) => response.data),
      map(user => this.mapUser(user))
    );
  }

  private mapUser(user: User): User {
    return {
      ...user,
      createdAt: moment(user.createdAt),
      updatedAt: moment(user.updatedAt),
    };
  }
}
